import css from "styled-jsx/css";
import { theme } from "../../../config";

const {
  colors: { Blue_3 },
} = theme;

const styles = css`
  .user-admin-table__header {
    background-color: ${Blue_3};

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: white;
    text-transform: capitalize;
  }

  .user-admin-table__header th {
    padding: 15px 0 14px 0;
    border: none;
    text-align: left;
  }
  .user-admin-table__header th:first-child {
    padding-left: 44px;
    border-radius: 4px 0px 0px 0px;
  }
  .user-admin-table__header th:last-child {
    padding-right: 19px;
    border-radius: 0px 4px 0px 0px;
  }

  .user-admin-table__header th.user-admin-table__header__controls {
    text-align: right;
  }

  .user-admin-table__header__sort-btn {
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  .user-admin-table__header__sort-btn:first-child {
    margin-left: 6px;
    margin-right: 3px;
  }
  .datePicker{
    max-width: 150px;
    margin: 0;
  }
`;

export default styles;
