import request from "../request";

export const passwordReset1 = async (tenant: string, email: string): Promise<boolean> => {
  return request<boolean>({
    endpoint: `auth/password-reset1`,
    config: {
      method: "POST",
      body: JSON.stringify({ tenant, email }),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const passwordReset2 = async (tenant: string, email: string, password: string, code: string): Promise<boolean> => {
  return request<boolean>({
    endpoint: `auth/password-reset2`,
    config: {
      method: "POST",
      body: JSON.stringify({ tenant, email, password, code }),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const passwordResetByUserId = async (userId: string): Promise<boolean> => {
  return request<boolean>({
    endpoint: `auth/password-reset-by-user-id`,
    config: {
      method: "POST",
      body: JSON.stringify({ userId }),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};
