import { defineMessages } from "react-intl";

const messages = defineMessages({
  selectedFileDescription: {
    id: "csvUpload.selectedFileDescription",
  },
  mapFields: {
    id: "csvUpload.mapFields",
  },
  next: {
    id: "csvUpload.next",
  },
  back: {
    id: "csvUpload.back",
  },
});

export default messages;
