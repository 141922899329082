import React from "react";

export default () => (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <path
      d="M2.25 12.9374V15.7499H5.0625L13.3575 7.45492L10.545 4.64242L2.25 12.9374ZM15.5325 5.27992C15.825 4.98742 15.825 4.51492 15.5325 4.22242L13.7775 2.46742C13.485 2.17492 13.0125 2.17492 12.72 2.46742L11.3475 3.83992L14.16 6.65242L15.5325 5.27992Z"
      fill="#989CA3"
    />
  </svg>
);
