import request from "../request";
import { Role } from "@tagworx/core";

export const getRoles = async (): Promise<Role[]> =>
  request<Role[]>({
    endpoint: "role/list",
    config: {
      method: "GET",
    },
  });

export const saveRole = async (data: Role): Promise<Role> =>
  request<Role>({
    endpoint: "role/",
    config: {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
      },
    },
  });

export const deleteRole = async (_id: string) =>
  request({
    endpoint: `role/delete/${_id}`,
    config: {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
    },
  });
