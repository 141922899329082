import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { MayaBlue, Grey_1 },
} = theme;
const styles = css`
  .selector {
    display: flex;
  }
  .selector__option {
    flex: 1;
    padding: 18px 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    text-transform: uppercase;
    padding:10px;
    border: 1px solid ${Grey_1};
    outline: none;
    background: none;
    color:white;
    cursor: pointer;
  }
  .selector__option:first-child {
    border-radius: 4px 0px 0px 4px;
  }
  .selector__option:last-child {
    border-radius: 0px 4px 4px 0px;
  }
  .selector__option--active {
    background-color: ${MayaBlue};
    color: white;
  }
`;

export default styles;
