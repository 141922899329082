import { Dispatch } from "redux";
import { IFetchUserActivityListBegin, IFetchUserActivityListFailure, IFetchUserActivityListSuccess, IUserActivityResponse } from "../models";
import { FETCH_ACTIVITY_USER_LIST_SUCCESS, FETCH_ACTIVITY_USER_LIST_FAILURE, FETCH_ACTIVITY_USER_LIST_BEGIN } from "../types";
import { getActivityUserList } from "../../../services/api";

export const fetchActivityUserListBegin = (): IFetchUserActivityListBegin => ({
  type: FETCH_ACTIVITY_USER_LIST_BEGIN,
});

export const fetchActivityUserListSuccess = (userActivityList: IUserActivityResponse[]): IFetchUserActivityListSuccess => ({
  type: FETCH_ACTIVITY_USER_LIST_SUCCESS,
  payload: userActivityList,
});

export const fetchActivityUserListFailure = (error: string): IFetchUserActivityListFailure => ({
  type: FETCH_ACTIVITY_USER_LIST_FAILURE,
  payload: error,
});

export default (filters:{}) => async (dispatch: Dispatch) => {
  dispatch(fetchActivityUserListBegin());
  try {
    const activityUserList = await getActivityUserList(filters);
    dispatch(fetchActivityUserListSuccess(activityUserList));
  } catch (e) {
    dispatch(fetchActivityUserListFailure(e.message));
  }
};
