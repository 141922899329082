import { FETCH_ACTIVITY_USER_LIST_BEGIN, FETCH_ACTIVITY_USER_LIST_FAILURE, FETCH_ACTIVITY_USER_LIST_SUCCESS } from "../types";
import { IFetchUserActivityListBegin, IFetchUserActivityListFailure, IFetchUserActivityListSuccess, IUserActivityInfo, IUserActivityState } from "../models";

const initialState: IUserActivityState = {
  data: [],
  error: null,
  loading: true,
};

export default (state: IUserActivityState = initialState, action: IFetchUserActivityListBegin | IFetchUserActivityListFailure | IFetchUserActivityListSuccess): IUserActivityState => {
  switch (action.type) {
    case FETCH_ACTIVITY_USER_LIST_BEGIN:
      return {
        data: state.data,
        loading: true,
        error: null,
      };
    case FETCH_ACTIVITY_USER_LIST_FAILURE:
      return {
        data: state.data,
        loading: false,
        error: action.payload,
      };
    case FETCH_ACTIVITY_USER_LIST_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
