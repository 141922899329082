import React, { useState, useCallback, SyntheticEvent } from "react";
import { IProps } from "./interfaces";
import styles from "./styles";
import { useIntl } from "react-intl";
import { updateUser, deleteUser, inviteUser, passwordResetByUserId, blockOrUnBlockUser } from "../../services/api";
import { UserInfoInputModal } from "..";
import { IUserInfo } from "../../store/userAdmin/models";
import { DeleteIcon, EditIcon } from "../../icons";
import { theme } from "../../config";
import { MdOutlineBlock } from "react-icons/md";
import { useSelector } from "react-redux";
import { IStateTree } from "../../store/interfaces";

const EditUser = ({ userInfo, updateList }: IProps) => {
  const intl = useIntl();
  const userState = useSelector(({ user }: IStateTree) => user);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (params: Partial<IUserInfo>) => {
      const response = await updateUser(params);
      if (response.success) {
        updateList();
      }
    },
    [updateList],
  );

  const handleDeleteUser = async () => {
    if (window.confirm(intl.formatMessage({ id: "editUser.confirmDelete1" } ) + userInfo.email + intl.formatMessage({ id: "editUser.confirmDelete2" }) )) {
      const success = await deleteUser(userInfo._id);
      if (success) {
        updateList();
      }
    }
  };

  const handleBlockOrUnBlockUser = async (isBlock: boolean | undefined) => {
    if (isBlock) {
      if (window.confirm(intl.formatMessage({ id: "editUser.confirmUnBlock1" }) + userInfo.email + intl.formatMessage({ id: "editUser.confirmUnBlock2" }))) {
        const success = await blockOrUnBlockUser(userInfo._id);
        if (success) {
          updateList();
        }
      }
    } else {
      if (window.confirm(intl.formatMessage({ id: "editUser.confirmBlock1" }) + userInfo.email + intl.formatMessage({ id: "editUser.confirmBlock2" }))) {
        const success = await blockOrUnBlockUser(userInfo._id);
        if (success) {
          updateList();
        }
      }
    }
  };

  const handleResendInvitation = async () => {
    try {
      await inviteUser(userInfo.email);
      alert(intl.formatMessage({ id: "editUser.invitationSent" }));
    } catch (err) {
      alert(err);
    }
  };

  const onPasswordReset = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!userInfo._id) {
      alert(intl.formatMessage({ id: "editUser.userIdNotFound" }));
      return;
    }
    if (window.confirm(intl.formatMessage({ id: "passwordReset.blockUser1" }) + userInfo.email + intl.formatMessage({ id: "passwordReset.blockUser2" }))) {
      try {
        await passwordResetByUserId(userInfo._id);
        alert(intl.formatMessage({ id: "passwordReset.emailsent" }));
        updateList();
      } catch (err) {
        alert(err);
      }
    }
    return;
  };

  return (
    <>
      {isModalOpen && <UserInfoInputModal title={intl.formatMessage({ id: "editUser.header" })} onSubmit={handleSubmit} userInfo={userInfo} close={() => setModalOpen(false)} />}
      <div className="edit-user__buttons">
        {userInfo.activatedAt && new Date(userInfo.activatedAt).getTime() > new Date().getTime() ? (
          <button className="edit-user__buttons__activatedAt">
            Start: {new Date(userInfo.activatedAt).getDate()}/{new Date(userInfo.activatedAt).getMonth() + 1}/{new Date(userInfo.activatedAt).getFullYear()}{" "}
            {new Date(userInfo.activatedAt).getHours()}:{new Date(userInfo.activatedAt).getMinutes()}:{new Date(userInfo.activatedAt).getSeconds()}
          </button>
        ) : (
          <>
            {!userInfo.hasPassword && (
              <button className="edit-user__button" onClick={handleResendInvitation}>
                <b>{intl.formatMessage({ id: "editUser.resendInvitation" })}</b>
              </button>
            )}
            {userInfo.hasPassword && (
              <button className="edit-user__buttons__resetPassword" onClick={onPasswordReset}>
                <b>{intl.formatMessage({ id: "editUser.resetPassword" })}</b>
              </button>
            )}
          </>
        )}
        <button className="edit-user__button" onClick={() => setModalOpen(true)}>
          <EditIcon />
        </button>
        <button
          disabled={!!(userState.data && userState.data.email === userInfo.email)}
          className={!!(userState.data && userState.data.email === userInfo.email) ? "edit-user__button edit-user__button__disabled" : "edit-user__button "}
          title="delete user"
          onClick={handleDeleteUser}
        >
          <DeleteIcon fill={userState.data && userState.data.email === userInfo.email ? "#e3e4e6" : "#989CA3"} />
        </button>
        {!(userState.data && userState.data.email === userInfo.email) ? 
          <button className="edit-user__button" title="Lock User" onClick={() => handleBlockOrUnBlockUser(userInfo.block)}>
            <MdOutlineBlock {...{ size: 20, color: `${!userInfo.block ? theme.colors.Grey_3 : theme.colors.Red_1}` }} />
          </button>:  
          <button className="edit-user__button edit-user__button__disabled" title="Lock User" disabled={true}>
            <MdOutlineBlock {...{ size: 20, color: "#e3e4e6" }} />
          </button>}
      </div>

      <style jsx={true}>{styles}</style>
    </>
  );
};

export default EditUser;
