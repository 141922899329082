import React from "react";
import cn from "classnames";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { Modal, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { INavItem } from "./interfaces";
import styles from "./styles";
import { SettingsIcon, KeyIcon, TagWorxIcon, SettingsNav } from "../../icons";
import { useIntl } from "react-intl";
import { theme, routes, externalApplications } from "../../config";
import { useSelector } from "react-redux";
import { IStateTree } from "../../store/interfaces";
import { FaUserAlt } from "react-icons/fa";
import { ImSwitch } from "react-icons/im";
import messages from "./messages";
import { useCheckPermission } from "../../hooks";

const {
  colors: { Gray_5 },
} = theme;

const { Text } = Typography;

export default () => {
  const history = useHistory();
  const intl = useIntl();
  const userState = useSelector(({ user }: IStateTree) => user);
  const readInventorySettingsPermission = useCheckPermission("inventory-settings", "read");
  const { data: userData } = userState;
  const applications = userData ? userData.applications : [];

  const onSignOut = async () => {
    history.push(routes.login);
  };

  const ConfirmContent = () => {
    return (
      <p>
        {intl.formatMessage(messages.wantTo)} <Text code={true}>{intl.formatMessage({ id: "common.signout" })}</Text> {intl.formatMessage(messages.ofTheApp)}?
      </p>
    );
  };

  const confirm = () => {
    // @ts-ignore
    const modal = Modal.confirm();
    modal.update({
      icon: <ExclamationCircleOutlined />,
      content: <ConfirmContent />,
      okText: intl.formatMessage({ id: "common.signout" }),
      cancelText: intl.formatMessage({ id: "common.cancel" }),
      onOk: () => {
        modal.destroy();
        setTimeout(() => {
          onSignOut();
        }, 200);
      },
    });
  };

  if (!userData) {
    onSignOut();
  }

  const handleLogout = () => {
    confirm();
  };

  const emptyNavItems: INavItem[] = [];

  const navItems: INavItem[] = applications.reduce((acc, appId) => {
    const externalApp = externalApplications.find((ea) => (ea.id = appId));

    return externalApp
      ? [
          {
            url: externalApp.url,
            icon: externalApp.icon,
            tooltip: intl.formatMessage({ id: "navbar.application" }),
          },
        ]
      : acc;
  }, emptyNavItems);

  const firstName = `${userData?.firstName?.charAt(0).toUpperCase()}${userData?.firstName?.slice(1)?.toLowerCase()}`;
  const lastName = `${userData?.lastName?.charAt(0).toUpperCase()}${userData?.lastName?.slice(1)?.toLowerCase()}`;

  const settingsItem: INavItem = { url: routes.generalSettings, icon: SettingsNav({ color: "#abb0ba", width: 24 }), tooltip: intl.formatMessage({ id: "navbar.settings" }) };
  const gotoSettings = () => {
    if (readInventorySettingsPermission) {
      window.location.href = `${process.env.REACT_APP_INVENTORY_ROUTING_URL}${routes.generalSettings}`;
    } else {
      history.push(`${routes.settings}`);
    }
  };

  return (
    <>
      <div className="navbar__container">
        <div className="navbar__item">
          <TagWorxIcon />
        </div>

        {navItems.map((item) => (
          <div className="navbar__item" key={item.url}>
            <div
              onClick={() => {
                window.location.href = item.url;
              }}
              data-tip={true}
              data-for={item.url}
              className="navbar__item__link"
            >
              {item.icon}
            </div>
            <ReactTooltip id={item.url} place="right" effect="solid" className="navbar__item__button__tooltip" backgroundColor={Gray_5}>
              {item.tooltip}
            </ReactTooltip>
          </div>
        ))}
        <div className="navbar__user">
          <div className="navbar__item">
            <div onClick={gotoSettings} data-tip={true} data-for={settingsItem.url} className="navbar__item__link__settings">
              {settingsItem.icon}
            </div>
            <ReactTooltip id={settingsItem.url} place="right" effect="solid" className="navbar__item__button__tooltip" backgroundColor={Gray_5}>
              {settingsItem.tooltip}
            </ReactTooltip>
          </div>

          <div className="navbar__item">
            <button
              className="navbar__Initial"
              onClick={() => {
                history.push(`${routes.myProfile}${routes.profileSettings}${routes.changePersonalDetails}`);
              }}
              data-tip={true}
              data-for="initials"
            >
              <span className={cn("avatar", { "no-avatar": userData?.firstName === "" && userData?.lastName === "" })}>
                {userData?.firstName === "" && userData?.lastName === "" ? (
                  <FaUserAlt size={15} />
                ) : (
                  <>
                    {userData?.firstName?.[0]}
                    {userData?.lastName?.[0]}
                  </>
                )}
              </span>
            </button>
            <ReactTooltip id="initials" place="right" effect="solid" className="navbar__item__button__tooltip" backgroundColor={Gray_5}>
              {userData?.firstName === "" && userData?.lastName === "" ? (
                <>{intl.formatMessage(messages.noName)}</>
              ) : (
                <>
                  {firstName} {lastName}
                </>
              )}
            </ReactTooltip>
          </div>
          <div className="navbar__item">
            <button className="navbar__signout-button" onClick={handleLogout} data-tip={true} data-for="signout" title="sign-out">
              <span className={cn("signout", "no-avatar")}>
                <ImSwitch size={25} />
              </span>
            </button>
            <ReactTooltip id="signout" place="right" effect="solid" className="navbar__item__button__tooltip" backgroundColor={Gray_5}>
              {intl.formatMessage({ id: "navbar.signout" })}
            </ReactTooltip>
          </div>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </>
  );
};
