import React from "react";
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useIntl } from "react-intl";
import { CalendarIcon } from "../../icons";
import { IProps } from "./interfaces";
import styles from "./styles";

const DatePicker = ({ onChange, value, placeholder, showTimeSelect }: IProps) => {
  const { formatDate } = useIntl();
  return (
    <>
      <div className="text-input" >
        <div>
          {value && <label className="text-input__label">{placeholder}</label>}
          <Picker
            isClearable={true}
            onChange={onChange}
            selected={value}
            customInput={
              <button type="button" className="datepicker">
                <div className="datepicker__text">{value ? formatDate(value) : <span className="datepicker_placeholder">{placeholder}</span>}</div>
                <CalendarIcon />
              </button>
            }
            showTimeSelect={showTimeSelect!!}
          />
        </div>
      </div>

      <style jsx={true}>{styles}</style>
    </>
  );
};

export default DatePicker;
