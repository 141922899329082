import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { black, white, Gray_1, Gray_3, Blue_1,Gray_4 },
} = theme;

export default css`
  .subnavigator__container {
    z-index: 1;
    position: relative;
  }

  h1 {
    font-size: 32px;
    font-weight: 900;
    padding: 24px 32px;
    margin: 0;
    background-color: ${Gray_3};
  }

  ul {
    padding-left: 32px;
    display: flex;
    margin: 0;
    background-color: ${white};
    box-shadow: 0px 4px 10px rgba(202, 202, 202, 0.25);
  }

  li {
    list-style: none;
    margin-right: 40px;
  }

  li :global(a) {
    color: ${Gray_4};
    text-decoration: none;
    padding: 9px 0;
    display: block;
    font-size: 18px;
    font-weight: 900;
  }

  li :global(.active-route) {
    color: ${black};
    position: relative;
  }

  li :global(.active-route)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: ${Blue_1};
  }
`;