import { Dispatch } from "redux";
import { IDeleteRoleBegin, IDeleteRoleFailure, IDeleteRoleSuccess } from "../models";
import { DELETE_ROLE_BEGIN, DELETE_ROLE_FAILURE, DELETE_ROLE_SUCCESS } from "../types";
import { deleteRole } from "../../../services/api";

const deleteRoleBegin = (): IDeleteRoleBegin => ({
  type: DELETE_ROLE_BEGIN,
});

const deleteRoleFailure = (error: string): IDeleteRoleFailure => ({
  type: DELETE_ROLE_FAILURE,
  payload: error,
});

const deleteRoleSuccess = (_id: string): IDeleteRoleSuccess => ({
  type: DELETE_ROLE_SUCCESS,
  payload: _id,
});

export default (_id: string) => async (dispatch: Dispatch) => {
  dispatch(deleteRoleBegin());
  try {
    await deleteRole(_id);
    dispatch(deleteRoleSuccess(_id));
  } catch (e) {
    const { message } = e as Error;
    dispatch(deleteRoleFailure(message));
  }
};
