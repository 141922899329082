import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { routes } from "../../config";
import { ProfileSettings } from "../../containers";

const ProfileRoute = () => {
  return (
    <Switch>
      <Route path={`${routes.myProfile}${routes.profileSettings}`} component={ProfileSettings} />
      <Route path={routes.myProfile} component={() => <Redirect to={`${routes.myProfile}${routes.profileSettings}${routes.changePersonalDetails}`} />} />
      <Route />
    </Switch>
  );
};

export default ProfileRoute;
