import React, { useEffect } from "react";
import { Page, SubNavigator, UserActivityTable } from "../../components";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { IStateTree } from "../../store/interfaces";
import styles from "./styles";
import { getSettings } from "../../store/settings/actions";
import { useIntl } from "react-intl";
import { UserQueryAttributes } from "@tagworx/core";
import { getUserActivityList } from "../../store/userActivity/actions";

const UserActivity = () => {
  const dispatch = useDispatch();
  const userActivityState = useSelector(({ userActivity }: IStateTree) => userActivity);
  const query: UserQueryAttributes = {
    sort: "email",
  };

  useEffect(() => {
    dispatch(getUserActivityList({}));
  }, [dispatch]);

  return (
    <Page>
      <div className="page-container">
        <SubNavigator page="settings" />
        <div className={cn("user-table__container", { "user-table__container--loading": userActivityState.loading })}>
         <UserActivityTable userActivityResponse={userActivityState.data} />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Page>
  );
};

export default UserActivity;
