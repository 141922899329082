import { defineMessages } from "react-intl";

const messages = defineMessages({
  saveRole: {
    id: "roles.saveRole",
  },
  permissions: {
    id: "roles.permissions",
  },
  view: {
    id: "roles.view",
  },
  edit: {
    id: "roles.edit",
  },
  deleteRole: {
    id: "roles.delete",
  },
  editRole: {
    id: "roles.editRole",
  },
  newRole: {
    id: "roles.newRole",
  },
});

export default messages;
