export default {
  "csvUpload.title": "Csv Upload",
  "csvUpload.import": "Import",
  "csvUpload.configure": "Choose a file",
  "csvUpload.mapFields": "Map Fields.",
  "csvUpload.preview": "Preview",
  "csvUpload.ready": "Ready",
  "csvUpload.selectFile": "Select File",
  "csvUpload.next": "Next",
  "csvUpload.back": "Back",
  "csvUpload.total": "Total number of users to import",
  "csvUpload.email.duplicate.number": "Duplicate emails",
  "csvUpload.email.duplicate": "Duplicate emails",
  "csvUpload.readyDescription": "All users have been added",
  "csvUpload.done": "Done",
  "csvUpload.importAll": "Import all",
  "csvUpload.duplicateEmails": "These email accounts are duplicated. Do you want to overwrite the user data and reset the accounts?",
  "csvUpload.tenantMaxUser.part0": "You have",
  "csvUpload.tenantMaxUser.part1": "registered users. Your license allows for a maximum of ",
  "csvUpload.tenantMaxUser.part2": ". You cannot add another",
  "csvUpload.tenantMaxUser.part3": "users. No user accounts were added. ",
  "csvUpload.domainForbidden": "Some of the emails imported have a invalid domain. Please check the emails and try again."
};
