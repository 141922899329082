import React from "react";

const SettingIconNav = (props: IProps) => (
  <div style={{ width: props.width, height: props.height }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <g transform="translate(885.601 -555.013)">
        <path
          fill={props.color ?? "currentColor"}
          fillRule="evenodd"
          d="M88.273,854.341a17.6,17.6,0,0,1,0,4.213q-1.885.466-4.6.932a13.054,13.054,0,0,1-1.592,3.842q1.593,2.253,2.6,3.915a17.61,17.61,0,0,1-2.978,2.978q-1.663-1-3.915-2.6a13.045,13.045,0,0,1-3.842,1.593q-.466,2.719-.932,4.6a17.6,17.6,0,0,1-4.213,0q-.466-1.885-.932-4.6a13.049,13.049,0,0,1-3.842-1.593q-2.253,1.593-3.915,2.6a17.62,17.62,0,0,1-2.978-2.978q1-1.663,2.6-3.915a13.055,13.055,0,0,1-1.593-3.842q-2.719-.467-4.6-.932a17.559,17.559,0,0,1,0-4.213q1.885-.466,4.6-.932a13.055,13.055,0,0,1,1.593-3.842q-1.594-2.253-2.6-3.915a17.611,17.611,0,0,1,2.978-2.978q1.663,1,3.915,2.6a13.05,13.05,0,0,1,3.842-1.593q.466-2.719.932-4.6a17.575,17.575,0,0,1,4.213,0q.466,1.885.932,4.6a13.046,13.046,0,0,1,3.842,1.593q2.253-1.594,3.915-2.6a17.6,17.6,0,0,1,2.978,2.978q-1,1.663-2.6,3.915a13.054,13.054,0,0,1,1.592,3.842Q86.389,853.874,88.273,854.341Zm-17.4-4.66a6.738,6.738,0,1,0,6.738,6.737A6.738,6.738,0,0,0,70.87,849.68Z"
          transform="translate(-939 -283.933)"
        />
      </g>
    </svg>
  </div>
);

SettingIconNav.defaultProps = {
  height: 35,
  width: 35,
};

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

export default SettingIconNav;
