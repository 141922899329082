import { defineMessages } from "react-intl";

const message = defineMessages({
  changePersonalDetails: {
    id: "profileSettings.changePersonalDetails",
  },
  changePassword: {
    id: "profileSettings.changePassword",
  },
});
export default message;
