import { defineMessages } from "react-intl";

const messages = defineMessages({
  changePersonalDetails: {
    id: "settings.account.menu.changePersonalDetails",
  },
  changePassword: {
    id: "settings.account.menu.changePassword",
  },
  changeCompanyDetails: {
    id: "settings.account.menu.changeCompanyDetails",
  },
});

export default messages;
