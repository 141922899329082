import React from "react";
import { Page } from "../../components";
import ProfileSubNav from "../../components/ProfileSubNav";
import styles from "./styles";
import { routes } from "../../config";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";
import PersonalDetails from "../AccountSettings/PersonalDetails";
import Password from "../AccountSettings/Password";
import message from "./message";
import { FormattedMessage } from "react-intl";
const ProfileSettings = () => {
  return (
    <Page>
      <ProfileSubNav />
      <div className="account-settings__container">
        <div className="account-settings__menu">
          <NavLink to={`${routes.myProfile}${routes.profileSettings}${routes.changePersonalDetails}`} exact className="menu-element" activeClassName="menu-element--active">
            <FormattedMessage {...message.changePersonalDetails} />
          </NavLink>
          <NavLink to={`${routes.myProfile}${routes.profileSettings}${routes.changePassword}`} exact className="menu-element" activeClassName="menu-element--active">
            <FormattedMessage {...message.changePassword} />
          </NavLink>
        </div>
        <div className="account-settings__content">
          <Switch>
            <Route path={`${routes.myProfile}${routes.profileSettings}${routes.changePersonalDetails}`} exact component={PersonalDetails} />
            <Route path={`${routes.myProfile}${routes.profileSettings}${routes.changePassword}`} exact component={Password} />
            <Route
              path={`${routes.myProfile}${routes.profileSettings}`}
              component={() => <Redirect to={`${routes.myProfile}${routes.profileSettings}${routes.changePersonalDetails}`} />}
            />
          </Switch>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Page>
  );
};
export default ProfileSettings;
