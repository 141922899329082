import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Role } from "@tagworx/core";
import { Plus } from "../../icons";
import { Page, SubNavigator, SmallButton } from "../../components";
import { getRoles, saveRole, deleteRole } from "../../store/roles/actions";
import { IStateTree } from "../../store/interfaces";
import RoleCard from "./RoleCard";
import RoleEditor from "./RoleEditor";
import styles from "./styles";
import messages from "./messages";
import { useCheckPermission } from "../../hooks";

const getNewRole = (roles: Role[]) => {
  const admin = roles?.find((r) => r.readOnly);
  const permissions: { [prop: string]: { write: boolean; read: boolean } } = {};
  if (admin) {
    Object.keys(admin.permissions).forEach((permission) => {
      permissions[permission] = {
        write: false,
        read: false,
      };
    });
  }
  const role: Role = {
    name: "",
    permissions,
  };
  return role;
};

const Roles = () => {
  const rolesState = useSelector((state: IStateTree) => state.roles);
  const writePermission = useCheckPermission("role", "write");

  const { loading, data: rawRoles } = rolesState;

  const newRole = getNewRole(rawRoles);
  const roles: Role[] = rawRoles?.map((role) => ({
    ...role,
    permissions: {
      ...newRole.permissions,
      ...role.permissions,
    },
  }));

  const [editedRole, setEditedRole] = useState<Role | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const handleCloseEditRole = useCallback(() => {
    setEditedRole(null);
  }, [setEditedRole]);

  const handleSaveRole = useCallback(
    async (role) => {
      await dispatch(saveRole(role));
      handleCloseEditRole();
    },
    [dispatch, handleCloseEditRole],
  );

  const handleDeleteRole = useCallback(
    async (role) => {
      await dispatch(deleteRole(role._id));
      handleCloseEditRole();
    },
    [handleCloseEditRole, dispatch],
  );

  return (
    <Page>
      <SubNavigator page="settings" />
      {!editedRole && (
        <div className="container">
          <div className="top-row">
            {writePermission && (
              <SmallButton type="deep-green" onClick={() => setEditedRole(newRole)}>
                <Plus />
                <FormattedMessage {...messages.newRole} />
              </SmallButton>
            )}
          </div>
          {!loading && roles?.length > 0 && (
            <div className="role-cards">
              {roles.map((role) => {
                return <RoleCard key={role._id} role={role} onEdit={(r) => setEditedRole(r)} writePermission={writePermission} />;
              })}
            </div>
          )}
        </div>
      )}
      {editedRole && <RoleEditor editedRole={editedRole} cancel={handleCloseEditRole} saveRole={handleSaveRole} deleteRole={handleDeleteRole} />}
      <style jsx={true}>{styles}</style>
    </Page>
  );
};

export default Roles;
