import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { sessionTimeLimit, pollingLimit } from "../../config";
import IProps from "./interfaces";

const watchedEvents = ["click", "scroll", "keydown"];
let currentEventHandler: () => void;

export const ActivityWatcher = ({ children, userState }: IProps) => {
  const history = useHistory();
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState(Date.now());
  const isLoggedIn = useCallback(() => {
    const { data, error, loading } = userState;
    return data !== null && error === null && !loading;
  }, [userState]);

  const handleActivity = useCallback(() => {
    const delta = Date.now() - lastActivityTimestamp;
    if (delta > sessionTimeLimit) {
      history.push("/login");
    }
    if (delta < pollingLimit) {
      return;
    }
    setLastActivityTimestamp(Date.now());
  }, [lastActivityTimestamp, setLastActivityTimestamp, history]);

  useEffect(() => {
    if (isLoggedIn()) {
      for (const event of watchedEvents) {
        document.removeEventListener(event, currentEventHandler);
        document.addEventListener(event, handleActivity);
      }
      currentEventHandler = handleActivity;
    } else {
      for (const event of watchedEvents) {
        document.removeEventListener(event, currentEventHandler);
      }
    }
  }, [handleActivity, isLoggedIn]);

  return <>{children}</>;
};

export default ActivityWatcher;
