import { applyMiddleware, createStore, Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "cookies-js";
import thunk from "redux-thunk";
import { APP_NAME } from "../config";
import { IStateTree } from "./interfaces";
import rootReducer from "./rootReducer";
import failureMiddleware from "./failureMiddleware";

// we are persisting the user state only, and share this among all microfrontends!
const persistConfig: PersistConfig<IStateTree> = {
  key: "user",
  whitelist: ["user"],
  storage: new CookieStorage(Cookies),
};

const middleware: Middleware[] = [thunk, failureMiddleware];

const composeEnhancers = composeWithDevTools({
  name: APP_NAME,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));
export const persistor = persistStore(store);
