import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Gray_2, Gray_3, Blue_1 },
} = theme;

export default css`
  .checkbox {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
  }

  .box-container {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid ${Gray_2};
    background-color: ${Gray_3};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
  }

  .box-container--checked {
    background-color: ${Blue_1};
    border: none;
  }
`;
