export interface IQuery {
  [prop: string]: string | number;
}

export function removeUndefinedAndZero<T>(rawQuery: T): IQuery {
  const query: IQuery = {};
  Object.entries(rawQuery).forEach(([prop, value]) => {
    if (value) {
      query[prop] = value;
    }
  });
  return query;
}
