import css from "styled-jsx/css";
import { theme } from "../../../config";

const {
  colors: { Red_1, Green_1, Grey_1, Gray_3, Comet, Grey_3, white, Blue_1, Gray_1, Blue_4 },
} = theme;

export default css`
  .multi-factor-spacer,
  .company-spacer {
    width: 406px;
  }
  .multi-factor-spacer {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }
  .multi-factor-spacer span,
  label {
    margin-top: 6px;
    display: block;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    font-family: Roboto;
  }
  .company-spacer {
    margin-bottom: 25px;
  }
  .message {
    display: inline-block;
    margin-left: 12px;
    color: ${Green_1};
  }

  .error {
    color: ${Red_1};
  }

  .text-input {
    display: flex;
    align-items: center;

    box-sizing: border-box;
    background: white;
    border: none;
    box-shadow: 0 0 0 1px ${Grey_1} inset;
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    padding: 0 16px;
    outline: none;
    background: ${Gray_3};

    transition: 200ms;
    cursor: text;
    width: 100px;
    height: 36px;
  }

  .text-input__input {
    outline: none;
    border: none;
    display: block;
    background: transparent;
    color: ${Comet};
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    line-height: 18px;
    padding: 0;
  }

  .multi-factor-spacer label {
    line-height: 25px;
  }

  .text-input--error {
    box-shadow: 0 0 0 2px ${Red_1} inset;
  }

  .text-description {
    font-size: 12px;
    color: ${Grey_3};
    margin-top: 5px;
  }

  .text-description--error {
    color: ${Red_1};
    font-weight: 400;
  }

  button {
    height: 24px;
    padding: 3px 12px 4px;
    border-radius: 4px;
    border: none;
    color: ${white};
    font-size: 14px;
    font-family: Lato;
    outline: none;
    cursor: pointer;
    font-weight: 900;
  }

  button:disabled {
    opacity: 0.7;
    cursor: default;
  }

  .button-type--blue {
    background-color: ${Blue_1};
  }
  .button-type--deep-blue {
    background-color: ${Blue_4};
  }

  .button-type--green {
    background-color: ${Green_1};
  }

  .button-type--outline {
    background-color: transparent;
    color: ${Gray_1};
    border: solid 1px ${Gray_1};
  }
  .button-mr {
    margin-right: 5rem;
  }
  .popOver {
    width: 150px;
    font-size: 12px;
    color: ${Grey_3};
  }
  .iconsPopOver {
    min-height: 2rem;
    width: 2rem;
    background: ${Grey_3};
  }
  .edit-user__button {
    margin-left: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${Blue_1};
  }
  .popover-input {
    display: flex;
    align-items: center;
  }
  .text-input__content {
    width: 200px;
  }
  .item-center {
    align-items: center;
  }
  .company-Url {
    margin-top: -10px;
  }
`;
