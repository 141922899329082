import { defineMessages } from "react-intl";

const messages = defineMessages({
  required: {
    id: "common.required",
  },
  passwordMinLength: {
    id: "common.passwordMinLength",
  },
  failure: {
    id: "accountCreate.failure",
  },
  header: {
    id: "accountCreate.header",
  },
  error: {
    id: "common.error",
  },
  password: {
    id: "accountCreate.password",
  },
  passworddescription: {
    id: "login.passworddescription",
  },
  reset: {
    id: "accountCreate.reset",
  },
  copyright: {
    id: "common.copyright",
  },
});

export default messages;
