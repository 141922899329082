import { IFetchUsersBegin, IFetchUsersFailure, IFetchUsersSuccess, IUserFetchState } from "../models";
import { FETCH_USER_BEGIN, FETCH_USER_FAILURE, FETCH_USER_SUCCESS } from "../types";

const initialState: IUserFetchState = {
  loading: false,
  error: null,
  data: null,
};

export default (state = initialState, action: IFetchUsersBegin | IFetchUsersFailure | IFetchUsersSuccess): IUserFetchState => {
  switch (action.type) {
    case FETCH_USER_BEGIN:
      return { ...state, loading: true };
    case FETCH_USER_FAILURE:
      return { ...initialState, error: action.payload };
    case FETCH_USER_SUCCESS:
      return { ...initialState, data: action.payload };
    default:
      return state;
  }
};
