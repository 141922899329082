import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { MayaBlue, Grey_1, Blue_3, PalePurple, DarkMayaBlue, MidMayaBlue },
} = theme;

const styles = css`
  .login__page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${Blue_3};
    overflow-y: auto;
  }

  .login__worxicon {
    padding: 30px;
  }

  .login__worxdescription {
    position: absolute;
    bottom: 47px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: ${PalePurple};
  }

  .login__form {
    width: 616px;
    padding: 60px 80px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid ${Grey_1};
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto;
  }

  .login__header {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 40px;
    margin-top: 0px;
  }

  .login__input {
    margin-bottom: 34px;
  }

  .login__button {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    padding: 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    cursor: pointer;
  }

  .login__button--signin {
    background: ${Blue_3};
    color: white;

    border: none;
    outline: none;
  }

  .login__button--signin:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .login__button--back {
    color: ${Blue_3};
    background: white;

    border: 2px solid ${Blue_3};
    box-sizing: border-box;
    border-radius: 4px;

    margin-top: 16px;
  }

  .login__linkbutton {
    display: block;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    font-weight: bold;
    font-size: 14px;
    text-decoration-line: underline;

    color: ${Blue_3};
    margin-bottom: 40px;
  }

  .login__error {
    margin-bottom: 34px;
    color: red;
  }
`;

export default styles;
