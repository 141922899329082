import { UserField, UserCSVFieldMap, fixedUserfields } from "@tagworx/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Dropdown, SmallButton } from "../../../../../../components";
import { getCsvFieldMap, saveCsvFieldMap } from "../../../../../../util";
import { IProps } from "./interfaces";
import messages from "./messages";
import styles from "./styles";

export default ({ currentIsThisForItems, file, onBack, onNext }: IProps) => {
  const { formatMessage } = useIntl();

  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [fieldMap, setFieldMap] = useState<UserCSVFieldMap>({});

  const fixedUserfieldsStringify = JSON.stringify(fixedUserfields);

  useEffect(() => {
    if (csvHeaders.length && fixedUserfields.length) {
      const storedFieldMap = getCsvFieldMap(currentIsThisForItems()) || {};
      const storedHeaders = Object.keys(storedFieldMap);
      storedHeaders.forEach((header) => {
        if (!csvHeaders.includes(storedFieldMap[header])) {
          delete storedFieldMap[header];
        }
      });
      const map: { [key: string]: string } = {};
      fixedUserfields.forEach((field: UserField) => {
        if (currentIsThisForItems() && ["quantity", "location"].includes(field.name)) return;
        if (storedFieldMap[field.name]) {
          map[field.name] = storedFieldMap[field.name];
        } else if (csvHeaders.includes(field.label)) {
          map[field.name] = field.label;
        }
      });
      setFieldMap(map);
    }
  }, [csvHeaders, fixedUserfieldsStringify, currentIsThisForItems]);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const rawCSVData = e.target?.result as string;
      const columnsEndIndex = rawCSVData.indexOf("\n");
      const columnsString = rawCSVData.substring(0, columnsEndIndex).trim();
      const columns = columnsString.split(",").map((c) => c.trim());
      setCsvHeaders(columns);
    };
    reader.readAsText(file);
  }, [file]);

  const getFieldRow = (field: UserField) => {
    return (
      <div key={field.name} className="map-field-row">
        <div className="map-field-name">{field.label || field.name}</div>
        <div className="map-field-dropdown">
          <Dropdown
            value={fieldMap[field.name] || ""}
            options={csvHeaderOptions}
            onChange={(value) => {
              setFieldMap((oldMap) => ({ ...oldMap, [field.name]: value }));
            }}
          />
        </div>
        {/*// @ts-ignore */}
        <style jsx={true}>{styles}</style>
      </div>
    );
  };

  const csvHeaderOptions = csvHeaders.map((column) => ({ label: column, value: column }));

  return (
    <>
      <h1> {formatMessage(messages.mapFields)}</h1>
      <div className="map-fields">
        <div className="fields-column">{fixedUserfields.map((field: UserField) => getFieldRow(field))}</div>
      </div>
      <div className="buttons">
        <SmallButton type="outline" onClick={onBack}>
          {formatMessage(messages.back)}
        </SmallButton>
        <SmallButton
          type="blue"
          onClick={() => {
            saveCsvFieldMap(currentIsThisForItems(), fieldMap);
            onNext(fieldMap);
          }}
        >
          {formatMessage(messages.next)}
        </SmallButton>
      </div>
      {/*// @ts-ignore */}
      <style jsx={true}>{styles}</style>
    </>
  );
};
