export default {
  "passwordReset.emailsent": "Password reset email sent",
  "passwordReset.failure": "Password reset failed",
  "passwordReset.request": "Send password reset instructions",
  "passwordReset.reset": "Reset password",
  "passwordReset.header": "Password reset",
  "passwordReset.password": "New Password",
  "passwordReset.tenantMissingFromURL": "Password reset link has no tenant informaton",
  "passwordReset.blockUser1": "Are you sure you want to reset the password of this email address ",
  "passwordReset.blockUser2": "? The account will be blocked until user resets password.",
};
