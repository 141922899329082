import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Blue_1 },
} = theme;
const styles = css`
  .button {
    padding: 16px 0;
    color: white;
    background-color: ${Blue_1};
    text-align: center;
    border-width: 0;
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;

    outline: none;
    box-sizing: border-box;
    cursor: pointer;
    min-width: 160px;
  }

  .button:disabled {
    opacity: 0.7;
  }
  .button--secondary {
    border: 2px solid ${Blue_1};
    background-color: white;
    color: ${Blue_1};
  }
  .button--fullwidth {
    width: 100%;
  }
  .button--marginLeft {
    margin-left: 12px;
  }
`;

export default styles;
