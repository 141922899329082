export default {
  "roles.Admin": "Admin",
  "roles.newRole": "New Role",
  "roles.view": "View",
  "roles.edit": "Edit",
  "roles.saveRole": "Save role",
  "roles.permissions": "Permissions",
  "roles.delete": "Delete role",
  "roles.editRole": "Edit role",
  "roles.confirmDelete": "Do you really want to delete this role?",
};
