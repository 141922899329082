import { defineMessages } from "react-intl";

const messages = defineMessages({
  view: {
    id: "roles.view",
  },
  edit: {
    id: "roles.edit",
  },
});

export default messages;
