import React from "react";

import { IProps } from "./interfaces";
import styles from "./styles";

const SmallButton = ({ children, onClick, type, disabled = false }: IProps) => (
  <>
    <button onClick={onClick} className={`button-type--${type}`} disabled={disabled}>
      {children}
    </button>
    <style jsx={true}>{styles}</style>
  </>
);

export default SmallButton;
