import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Comet, Grey_3, Grey_1, Gray_3, Blue_1, Red_1, Gray_5 },
} = theme;

const styles = css`
  .text-input {
    display: flex;
    align-items: center;

    box-sizing: border-box;
    background: white;
    border: none;
    box-shadow: 0 0 0 1px ${Grey_1} inset;
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    padding: 0 16px;
    outline: none;
    background: ${Gray_3};

    height: 56px;
    transition: 200ms;
    cursor: text;
  }

  .text-input--full-width {
    width: 100%;
  }

  .text-input__input {
    outline: none;
    border: none;
    display: block;
    background: transparent;
    color: ${Comet};
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    line-height: 18px;
    padding: 0;
  }

  .text-input__content {
    flex-basis: 80%;
  }

  .text-input__checkmark {
    margin-left: auto;
  }
  .text-input__checkmark :global(svg) {
    display: block;
  }

  .text-input__reveal {
    margin-left: auto;
    cursor: pointer;
    height: 16px;
  }

  .text-input__input::placeholder {
    color: ${Grey_3};
  }

  .text-input__label {
    display: block;
    font-size: 12px;
    line-height: 12px;
    color: ${Gray_5};
    margin-bottom: 6px;
  }

  .text-description {
    font-size: 12px;
    color: ${Gray_5};
    margin-top: 5px;
  }

  .text-input--focused {
    box-shadow: 0 0 0 2px ${Blue_1} inset;
    background-color: #f5f6f8;
  }

  .text-input--error {
    box-shadow: 0 0 0 2px ${Red_1} inset;
  }

  .text-description--error {
    color: ${Red_1};
  }
`;

export default styles;
