import request from "../request";
import querystring from "querystring";
import { UserResponse, UserInfo, BaseResponse, ChangePasswordInput, UserCSVFieldMap, UserCSVCheckResponse, UserQueryAttributes } from "@tagworx/core";
import { removeUndefinedAndZero } from "../../../util";
import { IUserActivityResponse } from "../../../store/userActivity/models";

export const getUser = async (): Promise<UserResponse> => {
  return request<UserResponse>({
    endpoint: `user/me`,
    config: {
      method: "GET",
    },
  });
};

export const saveUser = async (user: Partial<UserInfo>): Promise<BaseResponse> => {
  return request<BaseResponse>({
    endpoint: `user/me/`,
    config: {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const changePassword = async (input: ChangePasswordInput): Promise<BaseResponse> => {
  return request<BaseResponse>({
    endpoint: `user/me/password`,
    config: {
      method: "POST",
      body: JSON.stringify(input),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const getUserList = async (filters: {}): Promise<UserInfo[]> => {
  
  return request<UserInfo[]>({
    endpoint: `user/list?${querystring.encode(removeUndefinedAndZero(filters))}`,
    config: {
      method: "GET",
    },
  });
};

export const updateUser = async (user: Partial<UserInfo>): Promise<BaseResponse> => {
  return request<BaseResponse>({
    endpoint: `user/update/`,
    config: {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const createUser = (user: Partial<UserInfo>) => {
  return request<BaseResponse>({
    endpoint: "user/create",
    config: {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(user),
    },
  });
};

export const deleteUser = async (id: string): Promise<BaseResponse> => {
  return request<BaseResponse>({
    endpoint: `user/delete/${id}`,
    config: {
      method: "DELETE",
    },
  });
};

export const blockOrUnBlockUser = async (id: string): Promise<BaseResponse> => {
  return request<BaseResponse>({
    endpoint: `user/block-or-unblock/${id}`,
    config: {
      method: "PATCH",
    },
  });
};

export const inviteUser = (email: string) => {
  return request<BaseResponse>({
    endpoint: "user/invite",
    config: {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ email }),
    },
  });
};

const getFormData = (photo: File) => {
  const formData = new FormData();
  formData.append("file", photo);
  return formData;
};

export const saveUserItemsCSV = async (csv: File, fieldMap: UserCSVFieldMap, updateExisting: boolean, numberAddUsers: number): Promise<BaseResponse> => {
  const formData = getFormData(csv);
  formData.append("fieldMap", JSON.stringify(fieldMap));
  return request<BaseResponse>({
    endpoint: `user/items/csv?update=${updateExisting}&numberAddUsers=${numberAddUsers}`,
    config: {
      method: "POST",
      body: formData,
    },
  });
};

export const checkUserItemsCSV = async (csv: File, fieldMap: UserCSVFieldMap): Promise<UserCSVCheckResponse> => {
  const formData = getFormData(csv);
  formData.append("fieldMap", JSON.stringify(fieldMap));

  return request<UserCSVCheckResponse>({
    endpoint: "user/items/check-csv",
    config: {
      method: "POST",
      body: formData,
    },
  });
};

export const fetchUserItemsCSV = (query: UserQueryAttributes) =>
  request<string>({
    endpoint: `user/items/csv?${querystring.encode(removeUndefinedAndZero(query))}`,
    config: {
      method: "GET",
    },
  });

  export const getActivityUserList = async (filters: {}): Promise<IUserActivityResponse[]> => {
    return request<IUserActivityResponse[]>({
      endpoint: `user/activity?${querystring.encode(removeUndefinedAndZero(filters))}`,
      config: {
        method: "GET",
      },
    });
  };
