import css from "styled-jsx/css";

import { theme } from "../../config";

const {
  colors: { Grey_1, Gray_1, Blue_1, Blue_4, Gray_4 },
} = theme;

export default css`
  .account-settings__container {
    display: flex;
    min-height: calc(100vh - 183px);
  }
  .account-settings__menu {
    width: 191px;
    border-right: 1px solid ${Grey_1};
    padding-left: 32px;
    padding-top: 29px;
    box-sizing: border-box;
  }
  .account-settings__content {
    flex: 1;
    padding-top: 29px;
    padding-left: 54px;
    position: relative;
  }

  .account-settings__content :global(h2) {
    font-weight: 900;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .account-settings__menu :global(.menu-element) {
    font-size: 12px;
    color: ${Gray_4};
    text-decoration: none;
    display: block;
    margin-bottom: 16px;
  }

  .account-settings__menu :global(.menu-element--active) {
    font-weight: 900;
    color: ${Blue_4};
  }
  .disabled_div {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
  }
  :global(.disable svg){
    fill:${Gray_4}
  }
`;
