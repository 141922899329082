import css from "styled-jsx/css";
import { theme } from "../../../config";

const {
  colors: { Red_1, Green_1 },
} = theme;

export default css`
  .email-spacer,
  .phone-number-spacer {
    width: 406px;
  }
  .email-spacer {
    margin-bottom: 18px;
  }
  .phone-number-spacer {
    margin-bottom: 31px;
  }

  .message {
    display: inline-block;
    margin-left: 12px;
    color: ${Green_1};
  }

  .error {
    color: ${Red_1};
  }
`;
