import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { MayaBlue, Grey_1, Blue_3, PalePurple, MidMayaBlue },
} = theme;

const styles = css`
  .passwordreset__page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${Blue_3};
    overflow-y: scroll;
  }

  .passwordreset__worxicon {
    padding: 30px;
  }

  .passwordreset__worxdescription {
    position: absolute;
    bottom: 67px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: ${PalePurple};
  }

  .passwordreset__form {
    width: 616px;
    padding: 60px 80px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid ${Grey_1};
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto;
  }
  @media (max-width: 650px) {
    .passwordreset__form {
      width: calc(100% - 40px);
      padding-right: 20px;
      padding-left: 20px;
      position: static;
      transform: none;
      margin: auto;
    }

    .passwordreset__worxdescription {
      position: static;
      margin: 12px 0;
    }
  }

  .passwordreset__header {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 40px;
  }

  .passwordreset__input {
    margin-bottom: 34px;
  }

  .passwordreset__button {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    padding: 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    cursor: pointer;

    background: ${Blue_3};
    color: white;

    border: none;
    outline: none;
  }

  .passwordreset__error {
    margin-bottom: 34px;
    color: red;
  }
`;

export default styles;
