import React, { useRef, useState, useEffect, useCallback } from "react";
import cn from "classnames";
import styles from "./styles";
import { IProps } from "./interfaces";
import Checkmark from "../../icons/Checkmark";
import { Clear, Reveal } from "../../icons";

const TextInput = ({
  value,
  onChange,
  onReveal,
  placeholder,
  type,
  description,
  pattern,
  isValid = false,
  fullWidth = true,
  validityErrorMessage,
  error,
  focusOnMount,
  idFor,
  disabled,
}: IProps) => {
  const [validityError, setValidityError] = useState("");
  const [hasFocus, setFocus] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.setCustomValidity(validityError || "");
  }, [validityError, inputRef]);

  useEffect(() => {
    if (focusOnMount) {
      inputRef.current!.focus();
    }
  }, [focusOnMount]);

  const handleChange = useCallback(
    (newValue: string) => {
      if (validityErrorMessage) {
        inputRef.current?.validity.patternMismatch ? setValidityError(validityErrorMessage) : setValidityError("");
      }
      onChange(newValue);
    },
    [validityErrorMessage, onChange],
  );

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, [setFocus]);
  const hanldeBlur = useCallback(() => {
    setFocus(false);
  }, [setFocus]);

  const handleFocusInput = () => {
    inputRef!.current!.focus();
  };
  return (
    <>
      {!value && <label className="text-input__label" htmlFor={idFor}>{placeholder}</label>}
      <div className={cn("text-input", { "text-input--full-width": fullWidth, "text-input--focused": hasFocus, "text-input--error": !!error })} onClick={handleFocusInput}>
        <div className="text-input__content">
          {value && value.toString().length && <label className="text-input__label" htmlFor={idFor}>{placeholder}</label>}
          <input
            ref={inputRef}
            className="text-input__input"
            value={value}
            placeholder={placeholder}
            type={type}
            pattern={pattern}
            onFocus={handleFocus}
            onBlur={hanldeBlur}
            onChange={({ target: { value: newValue } }) => handleChange(newValue)}
            disabled={disabled}
            id={idFor}
          />
        </div>
        {isValid && !error && (
          <div className="text-input__checkmark">
            <Checkmark />
          </div>
        )}
        {!!error && (
          <div className="text-input__checkmark">
            <Clear />
          </div>
        )}
        {onReveal && (
          <div className="text-input__reveal" onClick={onReveal}>
            <Reveal />
          </div>
        )}
      </div>
      {description && <div className={cn("text-description", { "text-description--error": !!error })}>{error || description}</div>}
      
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default TextInput;
