import css from "styled-jsx/css";

export default css`
  .page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100vw - 87px);
  }
  .user-table__container {
    padding: 33px 22px 70px 32px;
    position: relative;
    flex: 1;
  }

  .user-table__container--loading {
    opacity: 0.7;
    pointer-events: none;
  }

  .user-table__container__buttons {
    display: flex;
    justify-content: space-around;
  }

  .user-table__container__buttons--loading {
    opacity: 0.7;
    pointer-events: none;
  }

  .user-table__top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
  }

  .user-table__top :global(button) {
    display: flex;
    margin-left: auto;
    margin-left: 2px
  }

  .user-table__top :global(button svg) {
    margin-right: 5px;
    margin-left: 5px;
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    margin-top: 2px;
    margin-bottom: 24px;
  }

  .edit-table__add-field {
    margin-top: 68px;
  }
  .edit-table__add-field :global(button) {
    width: 248px;
  }
  .user-info-input__input {
    margin-bottom: 17px;
  }
`;
