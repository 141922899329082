import React from "react";

export default () => (
  <svg width="14" height="14" viewBox="0 0 14 14">
    <path
      d="M11.0837 3.73949L10.2612 2.91699L7.00033 6.17783L3.73949 2.91699L2.91699 3.73949L6.17783 7.00033L2.91699 10.2612L3.73949 11.0837L7.00033 7.82283L10.2612 11.0837L11.0837 10.2612L7.82283 7.00033L11.0837 3.73949Z"
      fill="#E34343"
    />
  </svg>
);
