import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { DatePickerFilter, SmallButton } from "../..";
import getUserList from "../../../store/userAdmin/actions/getUserList";
import Selector from "../../Selector";
import styles from "./styles";

const UserAdminTableHeader = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<{blocked?: boolean, lastLogin?:Date}>({});
  const [selectorActive, setSelectorActive] = useState('active');

  return (
    <>
      <tr className="user-admin-table__header">
        <th>
          <FormattedMessage id="userAdminTable.name" />
        </th>
        <th>
          <FormattedMessage id="userAdminTable.email" />
        </th>
        <th>
          <FormattedMessage id="userAdminTable.mobile" />
        </th>
        <th>
          <FormattedMessage id="userAdminTable.role" />
        </th>
        <th>
          <div className='datePicker'>
            <DatePickerFilter 
              value={filters.lastLogin}
              placeholder={filters.lastLogin ? '' : 'Last Login'}
              onChange={value =>{ 
                value ? dispatch(getUserList({lastLogin: JSON.stringify(new Date(value))})): dispatch(getUserList({}))
                value ? setFilters({lastLogin: new Date(value)}) : setFilters({})
              }} 
              showTimeSelect={false}
            />

          </div>
        </th>
        <th style={{display: 'flex', flexDirection: 'row-reverse', marginLeft: '90px'}}>
            <Selector value = {selectorActive} options={[{label: 'Active', value: 'active'},{label: 'Deleted', value: 'deleted'}, {label: 'Locked', value: 'locked'}]} onChange={
              option=>{
                if(option.value === 'locked'){
                  dispatch(getUserList({blocked: true}))
                }
                if(option.value === 'active'){
                  dispatch(getUserList({}))
                }
                if(option.value === 'deleted'){
                  dispatch(getUserList({deleted: true}))
                }
                setSelectorActive(option.value)
              }
            }/>
        </th>
      </tr>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default UserAdminTableHeader;
