import { useSelector as useReduxSelector, TypedUseSelectorHook } from "react-redux";
import { IStateTree } from "../store/interfaces";
import { checkPermission, PermissionType } from "@tagworx/core";

const useSelector: TypedUseSelectorHook<IStateTree> = useReduxSelector;

export const useCheckPermission = (permission: string, accessType: PermissionType) => {
  const user = useSelector((state) => state.user.data);
  return !!(user && checkPermission(user.permissions, permission, accessType));
};
