import React, { useState, useEffect } from "react";
import TextInput from "../TextInput";
import { FormattedMessage, useIntl } from "react-intl";
import Dropdown from "../Dropdown";
import styles from "./styles";
import { validateMobileNumber, validateName, validateEmail } from "@tagworx/core";
import { formatMobileNumber, stripSpaces } from "../../util/formatting";
import { IOption } from "../Dropdown/interfaces";
import { getRoles } from "../../store/roles/actions";
import { IProps } from "./interfaces";
import { DatePicker, SmallButton } from "..";
import { useDispatch, useSelector } from "react-redux";
import { IStateTree } from "../../store/interfaces";

const UserInfoInputModal = ({ title, close, onSubmit, userInfo }: IProps) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { data: roleList } = useSelector(({ roles }: IStateTree) => roles);
  const { _id: initialId, email: initialEmail, firstName: initialFirstName, lastName: initialLastName, roleId: initialRoleId, mobile: initialMobile, expiredAt: initialExpiredAt, activatedAt: initialActivatedAt, } = userInfo || {};

  const [firstName, setFirstName] = useState(initialFirstName || "");
  const [lastName, setLastName] = useState(initialLastName || "");
  const [email, setEmail] = useState(initialEmail || "");
  const [mobile, setMobile] = useState(initialMobile || "");
  const [roleId, setRoleId] = useState(initialRoleId || "");
  const [expiredAt, setExpiredAt] = useState(initialExpiredAt || undefined);
  const [activatedAt, setActivatedAt] = useState(initialActivatedAt || undefined);
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const roleOptions = roleList.map<IOption>(({ name, _id }) => {
    return {
      label: name,
      value: _id || "",
    };
  });

  const onChangeFirstName = (value: string) => {
    const trimValue = !value || value === ' ' ? value.trim() : value
    if(!trimValue){
      setFirstNameError('Value empty first name')
    }else{
      setFirstNameError("")
    }
    setFirstName(trimValue)

  }

  const onChangeLastName = (value: string) => {
    const trimValue = !value || value === ' ' ? value.trim() : value
    if(!trimValue){
      setLastNameError('Value empty last name')
    }else{
      setLastNameError("")
    }
    setLastName(trimValue)

  }

  const submitAndClose = async () => {
    setSaving(true);
    const expiredAtObject = expiredAt ? {expiredAt} : {};
    const activatedAtObject = activatedAt ? {activatedAt} : {};
    try {
      await onSubmit({
        _id: initialId,
        email,
        firstName,
        lastName,
        mobile,
        roleId,
        ...expiredAtObject,
        ...activatedAtObject
      });
      close();
    } catch (e) {
      setSaving(false);
      alert(e);
    }
  };

  const selectedRole = roleOptions.find((role) => role.value === roleId);

  return (
    <>
      <div className="user-info-input__container">
        <div className="user-info-input__top">
          <h1>{title}</h1>
          <div className="user-info-input__action-buttons">
            <SmallButton type="deep-blue" onClick={submitAndClose} disabled={saving}>
              <FormattedMessage id={"common.save"} />
            </SmallButton>
            <SmallButton type="outline" onClick={close} disabled={saving}>
              <FormattedMessage id={"common.cancel"} />
            </SmallButton>
          </div>
        </div>

        <div className="user-info-input__input">
          <TextInput
            onChange={(value=>{onChangeFirstName(value)})}
            value={firstName}
            isValid={validateName(firstName)}
            description={intl.formatMessage({ id: "formDescriptions.firstName" })}
            placeholder={intl.formatMessage({ id: "formPlaceholders.firstName" })}
            idFor="first-name"
            error={firstNameError}
          />
        </div>
        <div className="user-info-input__input">
          <TextInput
             onChange={(value=>{onChangeLastName(value)})}
            value={lastName}
            isValid={validateName(lastName)}
            description={intl.formatMessage({ id: "formDescriptions.lastName" })}
            placeholder={intl.formatMessage({ id: "formPlaceholders.lastName" })}
            idFor="last-name"
            error={lastNameError}
          />
        </div>
        <div className="user-info-input__input">
          <TextInput
            onChange={setEmail}
            value={email}
            isValid={validateEmail(email)}
            description={intl.formatMessage({ id: "formDescriptions.email" })}
            placeholder={intl.formatMessage({ id: "formPlaceholders.email" })}
            idFor="email"
          />
        </div>
        <div className="user-info-input__input">
          <TextInput
            onChange={(value) => setMobile(stripSpaces(value))}
            value={formatMobileNumber(mobile)}
            isValid={validateMobileNumber(mobile)}
            description={intl.formatMessage({ id: "formDescriptions.mobileNumber" })}
            placeholder={intl.formatMessage({ id: "formPlaceholders.mobileNumber" })}
            idFor="mobile"
          />
        </div>
        <div className="user-info-input__input">
          <Dropdown
            onChange={(value) => setRoleId(stripSpaces(value))}
            value={selectedRole}
            options={roleOptions}
            placeholder={intl.formatMessage({ id: "formPlaceholders.role" })}
          />
        </div>
        <div className="user-info-input__input">
          <DatePicker 
            value={expiredAt ? new Date(expiredAt): undefined}
            placeholder={intl.formatMessage({ id: "formPlaceholders.expirationAt" })} 
            onChange={(value => value ? 
              (new Date(value).getTime() > new Date().getTime() ? 
                setExpiredAt(value): alert('This date is invalid')) : 
                setExpiredAt(undefined)
            )}
            showTimeSelect={false}
          />
        </div>
        <div className="user-info-input__input">
          <DatePicker 
            value={activatedAt ? new Date(activatedAt): undefined}
            placeholder={intl.formatMessage({ id: "formPlaceholders.activationAt" })} 
            onChange={(value => value ? 
              (new Date(value).getTime() > new Date().getTime() ? 
                setActivatedAt(value): alert('This date is invalid')) : 
                setActivatedAt(undefined)
            )} 
            showTimeSelect={false}
          />
        </div>
        <style jsx={true}>{styles}</style>
      </div>
    </>
  );
};

export default UserInfoInputModal;
