import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Grey_1, white, Blue_4 },
} = theme;

export default css`
  .switcher__container {
    width: 40px;
    height: 24px;
    border-radius: 12px;
    background-color: ${Grey_1};
    position: relative;
    cursor: pointer;
    transition: 200ms;
  }

  .switcher__toggle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${white};
    transition: 200ms;
  }

  .switcher__toggle--active {
    transform: translateX(16px);
  }

  .switcher__container--active {
    background-color: ${Blue_4};
  }
`;
