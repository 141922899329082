import { FETCH_USER_LIST_BEGIN, FETCH_USER_LIST_FAILURE, FETCH_USER_LIST_SUCCESS } from "../types";
import { IUserAdminState, IFetchUserListBegin, IFetchUserListFailure, IFetchUserListSuccess } from "../models";

const initialState: IUserAdminState = {
  data: [],
  error: null,
  loading: false,
};

export default (state: IUserAdminState = initialState, action: IFetchUserListBegin | IFetchUserListFailure | IFetchUserListSuccess): IUserAdminState => {
  switch (action.type) {
    case FETCH_USER_LIST_BEGIN:
      return {
        data: state.data,
        loading: true,
        error: null,
      };
    case FETCH_USER_LIST_FAILURE:
      return {
        data: state.data,
        loading: false,
        error: action.payload,
      };
    case FETCH_USER_LIST_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
