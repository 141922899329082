export default {
  "editUser.header": "Edit User",
  "editUser.name": "Name",
  "editUser.email": "Email",
  "editUser.submitButtonLabel": "Save",
  "editUser.actionButtonLabel": "Edit User",
  "editUser.delete": "Delete User",
  "editUser.confirmDelete1": "Do you really want to delete the email address ",
  "editUser.confirmDelete2": "?",
  "editUser.confirmBlock1": "Do you really want to block the email address ",
  "editUser.confirmBlock2": "?",
  "editUser.confirmUnBlock1": "Do you really want to unblock this email address ",
  "editUser.confirmUnBlock2": "?",
  "editUser.resendInvitation": "Resend invitation",
  "editUser.invitationSent": "Invitation sent",
  "editUser.resetPassword": "Reset password",
  "editUser.userIdNotFound": "User id not found",
};
