export default {
  "settings.account.menu.changePersonalDetails": "Change personal details",
  "settings.account.menu.changePassword": "Change password",
  "settings.account.menu.changeCompanyDetails": "Change company details",
  "settings.account.phoneNumber.placeholder": "Phone number",
  "settings.account.phoneNumber.description": "e.g.: +36301234567",
  "settings.account.save": "Save Changes",
  "settings.account.password.current.placeholder": "Current password",
  "settings.account.password.new.placeholder": "New password",
  "settings.account.password.confirmNew.placeholder": "Confirm new password",
  "settings.account.password.description": "e.g.: Min 8 characters including at least one number",
  "settings.account.confirm": "Confirm",
  "settings.account.confirm.error": "Confirm password is not identical to New password",
  "settings.account.company.placeholder": "Company",
  "settings.account.company.description": "e.g.: MyCompany",
  "settings.account.multiFactor": "Multi-factor authentication",
  "settings.account.passwordPolicy": "Password policy (minimum characters)",
  "settings.account.apploggout": "Auto-Logout App Users After (mins.)",
  "settings.account.apploggouttext": "Minimum logout time is 5 mins. Set to 0 mins, to disable.",
  "settings.account.passwordPolicy.moreThanSevenNumber": "Minimum password length is 8 characters",
  "settings.account.moreThanFiveNumber": "Minimum logout time: 5 mins.",
  "settings.account.passwordPolicy.requiredError": "Password length field is required",
  "settings.account.passwordPolicy.noDecimalError": "Password length field is not decimal",
  "settings.account.domainPermitted": "User account emails are restricted to the following domains:",
  invalidEmail: "Invalid e-mail address!",
  "settings.account.restrictUserDomainTitle": "User email addresses are restricted to the following domains",
  "settings.account.restrictUserDomainHelp": "Limit new users to a set of pre-defined domains",
  "settings.account.deleteDomainConfirm": "Are you sure you want to delete this domain?",
  "settings.account.lockUsersDays": "Automatically lock dormant account after (days)",
  "settings.account.lockUserDaysText": "Minimum Auto-lock time is 1 day. Set to 0 days, to disable",
};
