import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Page, SubNavigator } from "../../components";
import { routes } from "../../config";
import messages from "./messages";
import styles from "./styles";
import { useSelector } from "react-redux";
import CompanyDetails from "./CompanyDetails";

const accountSettingRoutes = [
  {
    to: `${routes.settings}${routes.accountSettings}`,
    exact: true,
    title: messages.changeCompanyDetails,
    component: CompanyDetails,
  },
];
const AccountSettings = () => {
  const user = useSelector((state: any) => state.user);
  return (
    <Page>
      <SubNavigator page="settings" />
      <div className="account-settings__container ">
        <div className={`account-settings__content ${user?.data?.role !== "Admin" && "disable"} `}>
          {user?.data?.role !== "Admin" && <div className=" disabled_div "></div>}
          <Switch>
            {accountSettingRoutes.map(({ to, component, exact }) => (
              <Route key={to} path={to} exact={exact} component={component} />
            ))}
          </Switch>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Page>
  );
};

export default AccountSettings;
