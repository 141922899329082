import React, { useState } from "react";
import { useIntl } from "react-intl";

import { IProps } from "./interfaces";
import styles from "./styles";
import "rc-steps/assets/index.css";

import Close from "./icons/close";

import messages from "./messages";
import { UserCSVFieldMap } from "@tagworx/core";
import { Step1, Step2, Step3, Step4 } from "./icons/steps";
import Steps, { Step } from "rc-steps";

function isForItems(this: { fragment: string }) {
  return this.fragment === "items";
}

const CsvImport = ({ fragment, close, updateUserList }: IProps) => {
  const { formatMessage } = useIntl();

  const [currentStep, setCurrentStep] = useState(0);
  const [csv, setCsv] = useState<File>();
  const [fieldMap, setFieldMap] = useState<UserCSVFieldMap>({});

  const handleOnBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFileSelect = (selectedFile: File) => {
    setCsv(selectedFile);
    setCurrentStep(1);
  };

  const currentIsThisForItems = isForItems.bind({ fragment });

  const getContent = (step: number) => {
    switch (step) {
      case 0:
        return <Step1 onSelect={handleFileSelect} />;
      case 1:
        return (
          <Step2
            file={csv!}
            currentIsThisForItems={currentIsThisForItems}
            onBack={handleOnBack}
            onNext={(map) => {
              setFieldMap(map);
              setCurrentStep(2);
            }}
          />
        );
      case 2:
        return <Step3 updateUserList={updateUserList} onBack={handleOnBack} onNext={() => setCurrentStep(3)} file={csv!} fieldMap={fieldMap} onDone={close} />;
      case 3:
        return <Step4 onDone={close} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div>
        <div className="header">
          <header className="top-row">
            <div className="title">{formatMessage(messages.title)}</div>
            <div>
              <button className="close" onClick={() => close()} title="close">
                <Close />
              </button>
            </div>
          </header>
          <div style={{ padding: "10px", maxWidth: "600px" }}>
            <Steps current={currentStep}>
              <Step key={1} title={formatMessage(messages.configure)} />
              <Step key={2} title={formatMessage(messages.mapFields)} />
              <Step key={3} title={formatMessage(messages.preview)} />
              <Step key={4} title={formatMessage(messages.ready)} />
            </Steps>
          </div>
        </div>
        <div className="content">{getContent(currentStep)}</div>
      </div>
      {/*// @ts-ignore */}
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default CsvImport;
