import { AnyAction } from "redux";
import { SAVE_SETTINGS_FAILURE } from "./settings/types";

const errorTypes = ["FAILURE"];

const blacklist = [SAVE_SETTINGS_FAILURE];

const failureMiddleware = () => (next: (action: AnyAction) => void) => (action: AnyAction) => {
  const isErrorType = errorTypes.some((type) => new RegExp(`.+_${type}`).test(action.type)) && !blacklist.includes(action.type);
  if (isErrorType) {
    const error = action.payload;
    alert(error);
  }
  next(action);
};

export default failureMiddleware;
