import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserResponse } from "../../../core/lib";
import { PasswordReset, LoginComponent, AccountCreate } from "../components";
import { routes, externalApplications } from "../config";
import { IStateTree } from "../store/interfaces";
import SettingsRoute from "./SettingsRoute";
import ProfileRoute from "./ProfileRoute";

const getInitialRoute = (userData: UserResponse | null) => {
  if (userData && userData.applications.length) {
    /*// @ts-ignore */
    let initialApplication: any = "";

    /*// @ts-ignore */
    if (userData.applications.includes("ukho") && !userData.applications.includes("inventory")) {
      initialApplication = externalApplications[0];
      /*// @ts-ignore */
    } else if (userData.applications.includes("capacity-dashboard") && !userData.applications.includes("inventory")) {
      initialApplication = externalApplications[0];
    }else if (userData.permissions["integration"]?.read === true  && userData.permissions["inventory"]?.read === false) {
      initialApplication = externalApplications[0];
    } else {
      initialApplication = externalApplications.find((ea) => ea.id === userData.applications[0]);
    }

    return initialApplication ? initialApplication.url : routes.settings;
  }
  return routes.settings;
};

const Router = () => {
  const userState = useSelector(({ user }: IStateTree) => user);
  const { data: userData } = userState;

  const initialRoute = getInitialRoute(userData);

  const publicRouter = (
    <Switch>
      <Route exact={true} path={routes.login} component={LoginComponent} />
      <Route exact={true} path={routes.passwordReset} component={PasswordReset} />
      <Route exact={true} path={routes.accountCreate} component={AccountCreate} />
      <Route path="*" component={() => <Redirect to={routes.login} />} />
    </Switch>
  );

  const privateRouter = (
    <Switch>
      <Route exact={true} path={routes.login} component={LoginComponent} />
      <Route exact={true} path={routes.passwordReset} component={PasswordReset} />
      <Route exact={true} path={routes.accountCreate} component={AccountCreate} />
      <Route path={routes.settings}  component={SettingsRoute} />
      <Route path={routes.myProfile}  component={ProfileRoute} />
      <Route
        path="*"
        component={() => {
          window.location.href = initialRoute;
          return null;
        }}
      />
    </Switch>
  );

  return userData ? privateRouter : publicRouter;
};

export default Router;
