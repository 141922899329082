import React from "react";

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g clipPath="url(#clip0)">
      <path d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
