import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Blue_2, Blue_6, white, Blue_7, Blue_8 },
} = theme;

const styles = css`
  .navbar__container {
    display: flex;
    flex-direction: column;
    width: 58px;
    z-index: 2;
    box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: ${Blue_6};
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .navbar__item {
    text-align: center;
  }

  .avatar {
    border-radius: 50%;
    background-color: ${Blue_8};
    color: ${white};
    font-size: 15px;
    width: 45px;
    height: 45px;
    display: inline-block;
    line-height: 45px;
  }

  :global(.navbar__item__link > svg) {
    width: 100%;
  }

  :global(.navbar__item > svg) {
    padding: 0 14px;
    width: 30px;
  }
  .navbar__user {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .navbar__signout-button {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px;
  }

  :global(.navbar__item__link) {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px;
  }

  :global(.navbar__item__link) :global(svg) {
    display: block;
  }
  :global(.navbar__item__link--active) {
    background-color: ${Blue_2};
    border-radius: 4px;
  }
  .navbar__Initial {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding-left: 6px;
  }

  :global(.navbar__item__button__tooltip) {
    font-family: Roboto;
    font-size: 14px;
  }
  .navbar__signout-button {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding-left: 17px;
  }
  :global(.navbar__item__link__settings) {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
    padding-top: 16px;
    // padding-left: 16px;
    cursor: pointer;
    color: ${Blue_7};
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .signout {
    cursor: pointer;
    color: white;
  }
  :global(.avatar.no-avatar) {
    background: #289cff;
    position: relative;
  }

  :global(.avatar.no-avatar svg) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default styles;
