import { combineReducers } from "redux";
import { IRootReducer } from "./interfaces";

import { user } from "./user/reducers";
import { userAdmin } from "./userAdmin/reducers";
import { settings } from "./settings/reducers";
import { userActivity } from "./userActivity/reducers";

import { roles } from "./roles/reducers";

const rootReducer: IRootReducer = combineReducers({
  user,
  userAdmin,
  settings,
  roles,
  userActivity,
});

export default rootReducer;
