import { defineMessages } from "react-intl";

const messages = defineMessages({
  confirmLogout: {
    id: "navbar.signout.confirm",
  },
  noName: {
    id: "navbar.signout.noname",
  },
  wantTo: {
    id: "common.wantTo",
  },
  ofTheApp: {
    id: "common.ofTheApp",
  },
});

export default messages;
