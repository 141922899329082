export const formatMobileNumber = (mobileNr: string) => {
  const strippedMobileNr = stripSpaces(mobileNr);
  const { length } = strippedMobileNr;
  if (length > 8) {
    return `${strippedMobileNr.slice(0, 5)} ${strippedMobileNr.slice(5, 8)} ${strippedMobileNr.slice(8)}`;
  }
  if (length > 5) {
    return `${strippedMobileNr.slice(0, 5)} ${strippedMobileNr.slice(5)}`;
  }
  return mobileNr;
};

export const stripSpaces = (value: string) => value.replace(/\s+/g, "");
