import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Gray_3, Grey_1, Grey_3, Comet, Blue_1 ,Grey_4},
} = theme;

const styles = css`
  .dropdown {
    position: relative;
  }
  .dropdown__label {
    position: absolute;
    top: 8px;
    left: 16px;
    width: calc(100% - 16px);
    user-select: none;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: ${Grey_3};
  }
  .text-description {
    font-size: 12px;
    color: ${Grey_3};
    margin-top: 5px;
  }
  :global(.dropdown .dropdown__toggle-btn .is-selected) {
    color: ${Comet};
  }
  :global(.dropdown__toggle-btn, .dropdown__toggle-btn:hover) {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    background: ${Gray_3};
    border: none;
    box-shadow: 0 0 0 1px ${Grey_1} inset;
    box-sizing: border-box;
    border-radius: 4px;

    height: 56px;
    max-height: 56px;
    padding: 0 16px;
  }

  :global(.is-open .dropdown__toggle-btn) {
    box-shadow: 0 0 0 2px ${Blue_1} inset;
    background-color: #f5f6f8;
  }

  :global(.dropdown__menu) {
    background-color: ${Gray_3};

    border: 1px solid ${Grey_1};
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 8px;
    padding: 8px;
  }
  :global(.dropdown__menu > .dropdown__menu__option) {
    min-height: 32px;
    color: ${Grey_3};
    font-family: Roboto;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
  }
  :global(.dropdown__menu > .dropdown__menu__option:not(:last-child)) {
    margin-bottom: 8px;
  }
  :global(.Dropdown-arrow-wrapper svg) {
    display: block;
  }

  :global(.dropdown__menu > .dropdown__menu__option:hover) {
    background-color: ${Blue_1};
    color: white;
    font-weight: 700;
  }
  :global(.dropdown__menu > .dropdown__menu__option--active) {
    background-color: ${Blue_1};
    font-weight: 700;
    color: white;
  }

  :global(.dropdown__toggle-btn > *:first-child) {
    flex: 1;
  }
  :global(.dropdown__toggle-btn--selected > *:first-child) {
    flex: 1;
    padding-top: 18px;
  }
  :global(.dropdown__placeholder) {
    color: ${Grey_4};
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
`;

export default styles;
