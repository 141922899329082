import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Comet, Grey_3, Grey_1, Gray_3,Grey_4 },
} = theme;

const styles = css`
  .text-input {
    display: flex;
    align-items: center;

    box-sizing: border-box;
    background: white;
    border: 1px solid ${Grey_1};
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    padding: 0 16px;
    outline: none;
    background: ${Gray_3};

    height: 56px;
  }

  .text-input > div {
    width: 100%;
  }

  .text-input :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  .datepicker_placeholder {
    color: ${Grey_4};
  }

  .text-input__label {
    display: block;
    font-size: 12px;
    color: ${Grey_3};
    margin-bottom: 6px;
  }

  .datepicker {
    cursor: pointer;
    width: 100%;
    outline: none;
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 32px;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }
  .datepicker__text {
    color: ${Comet};
    text-align: left;
    flex: 1;
  }
`;

export default styles;
