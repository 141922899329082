import css from "styled-jsx/css";
import { theme } from "../../../config";

const {
  colors: { Red_1, Green_1 },
} = theme;

export default css`
  .small-spacer,
  .large-spacer {
    width: 406px;
  }
  .small-spacer {
    margin-bottom: 18px;
  }
  .large-spacer {
    margin-bottom: 31px;
  }
  .message {
    display: inline-block;
    margin-left: 12px;
    color: ${Green_1};
  }

  .error {
    color: ${Red_1};
  }
`;
