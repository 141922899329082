import React from "react";
import Header from "./Header";
import Row from "./Row";
import styles from "./styles";
import { IProps } from "./interfaces";

const UserActivityTable = ({ userActivityResponse }: IProps) => (
  <>
    <table className="user-admin-table">
      <tbody>
        <Header />
        {userActivityResponse.map((userActivityResponse) => (
          <Row key={userActivityResponse._id} userActivityResponse={userActivityResponse} />
        ))}
      </tbody>
    </table>
    <style jsx={true}>{styles}</style>
  </>
);

export default UserActivityTable;
