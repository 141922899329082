import React from "react";
import ReactDOM from "react-dom";
import "typeface-prompt";
import App from "./App";
import Provider from "./store/Provider";

ReactDOM.render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById("root"),
);
