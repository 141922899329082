export default {
  "navbar.signout": "Sign Out",
  "navbar.settings": "Settings",
  "navbar.inventory": "Inventory",
  "navbar.signout.confirm": "You are about to logout. Are you sure?",
  "navbar.signout.noname":"No Name Found",
  "common.wantTo": "Want to",
  "common.ofTheApp": "of this application",
  "navbar.application": "Application",
};
