import React, { SyntheticEvent, useCallback, useState } from "react";
import cn from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { validateEmail } from "@tagworx/core";
import { TextInput, SmallButton } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { IStateTree } from "../../../store/interfaces";
import styles from "./styles";
import messages from "./messages";
import { saveUser } from "../../../services/api";
import { getUser } from "../../../store/user/actions";

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { data: userData } = useSelector(({ user }: IStateTree) => user);
  const [message, setMessage] = useState<{ error: boolean; text: string } | null>(null);
  const [email, setEmail] = useState(userData?.email ? userData?.email : "");
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState(userData?.mobile ? userData.mobile : "");
  const [saving, setSaving] = useState(false);

  const handleChangeEmail = useCallback(
    (value) => {
      setEmail(value);
      setEmailError(undefined);
    },
    [setEmail],
  );

  const handleSaveSettings = async (event: SyntheticEvent) => {
    event.preventDefault();
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      setEmailError(formatMessage(messages.invalidEmail));
      return;
    }
    try {
      setMessage(null);
      setSaving(true);
      const response = await saveUser({ email, mobile: phoneNumber });
      if (response.success) {
        dispatch(getUser());
        setMessage({
          error: false,
          text: formatMessage(messages.success),
        });
      } else {
        setMessage({
          error: true,
          text: formatMessage(messages.error),
        });
      }
    } catch (e:any) {
      setMessage({
        error: true,
        text: e.message,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <form>
      <h2>
        <FormattedMessage {...messages.title} />
      </h2>
      <div className="email-spacer">
        <TextInput
          error={emailError}
          value={email}
          placeholder={formatMessage(messages.emailPlaceholder)}
          description={formatMessage(messages.emailDescription)}
          onChange={handleChangeEmail}
          idFor="email-space"
        />
      </div>
      <div className="phone-number-spacer">
        <TextInput
          value={phoneNumber}
          placeholder={formatMessage(messages.phoneNumberPlaceholder)}
          description={formatMessage(messages.phoneNumberDescription)}
          onChange={setPhoneNumber}
          idFor="phone-number-spacer"
        />
      </div>
      <SmallButton type="deep-blue" onClick={handleSaveSettings} disabled={saving}>
        <FormattedMessage {...messages.submit} />
      </SmallButton>
      {message && <div className={cn("message", { error: message.error })}>{message.text}</div>}
      <style jsx={true}>{styles}</style>
    </form>
  );
};

export default PersonalDetails;
