import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { MayaBlue, Grey_1 },
} = theme;

const styles = css`
  .toggle__container {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  }

  .toggle__track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: ${Grey_1};
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .toggle__track--checked {
    background-color: ${MayaBlue};
  }

  .toggle__thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 50%;
    background-color: white;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .toggle__thumb--checked {
    left: 27px;
  }
`;

export default styles;
