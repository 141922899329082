import css from "styled-jsx/css";
import { theme } from "../../../config";

const {
  colors: { Grey_1, Gray_1,Gray_4,Blue_4 },
} = theme;

export default css`
  .header {
    border-bottom: 1px solid ${Grey_1};
    padding: 4px 23px 10px;
  }

  .top-row {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close {
    padding: 10px;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  .close :global(svg) {
    display: block;
  }

  .title {
    color: ${Gray_4};
    font-size: 18px;
    font-weight: 900;
    margin-left: 10px;
  }

  .content {
    margin: 30px;
  }

  .steps-content {
    padding: 10px;
    max-width: 600px;
  }
  :global(.rc-steps-item-process .rc-steps-item-icon ){
    background-color:${Blue_4}
  }
  :global(.rc-steps-item-wait .rc-steps-item-icon){
    border-color:${Gray_4};
  }
  :global(.rc-steps-item-wait .rc-steps-item-icon .rc-steps-icon){
    color:${Gray_4};
  }
`;
