import React, { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "classnames";
import { TextInput, SmallButton } from "../../../components";
import styles from "./styles";
import messages from "./messages";
import { changePassword } from "../../../services/api";

const Password = () => {
  const { formatMessage } = useIntl();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState<string | undefined>(undefined);
  const [newPasswordError, setNewPasswordError] = useState<string | undefined>(undefined);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState<{ error: boolean; text: string } | null>(null);

  const handleChangePassword = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      if (!currentPassword) {
        setCurrentPasswordError(formatMessage(messages.required));
      }
      if (!newPassword) {
        setNewPasswordError(formatMessage(messages.required));
      }
      if (!confirmNewPassword) {
        setConfirmNewPasswordError(formatMessage(messages.required));
      }
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordError(formatMessage(messages.confirmPasswordError));
      return;
    }
    // if (newPassword.length < 8) {
    //   setNewPasswordError(formatMessage(messages.passwordMinLength));
    //   return;
    // }
    try {
      setSaving(true);
      setMessage(null);
      const response = await changePassword({ currentPassword, newPassword });
      if (response.success) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setMessage({
          error: false,
          text: formatMessage(messages.success),
        });
      } else {
        setMessage({
          error: true,
          text: formatMessage(messages.error),
        });
      }
    } catch (e:any) {
      setMessage({
        error: true,
        text: e.message,
      });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    setCurrentPasswordError(undefined);
  }, [currentPassword, setCurrentPasswordError]);
  useEffect(() => {
    setNewPasswordError(undefined);
  }, [newPassword, setNewPasswordError]);
  useEffect(() => {
    setConfirmNewPasswordError(undefined);
  }, [confirmNewPassword, setConfirmNewPasswordError]);

  return (
    <form>
      <h2>
        <FormattedMessage {...messages.title} />
      </h2>
      <div className="small-spacer">
        <TextInput
          value={currentPassword}
          placeholder={formatMessage(messages.currentPasswordPlaceholder)}
          // description={formatMessage(messages.passwordDescription)}
          onChange={setCurrentPassword}
          type="password"
          error={currentPasswordError}
          idFor="current-password"
        />
      </div>
      <div className="small-spacer">
        <TextInput
          value={newPassword}
          placeholder={formatMessage(messages.newPasswordPlaceholder)}
          // description={formatMessage(messages.passwordDescription)}
          onChange={setNewPassword}
          type="password"
          error={newPasswordError}
          idFor="new-password"
        />
      </div>
      <div className="large-spacer">
        <TextInput
          value={confirmNewPassword}
          placeholder={formatMessage(messages.confirmNewPasswordPlaceholder)}
          // description={formatMessage(messages.passwordDescription)}
          onChange={setConfirmNewPassword}
          type="password"
          error={confirmNewPasswordError}
          idFor="confirm-password"
        />
      </div>
      <SmallButton type="deep-blue" onClick={handleChangePassword} disabled={saving}>
        <FormattedMessage {...messages.confirm} />
      </SmallButton>
      {message && <div className={cn("message", { error: message.error })}>{message.text}</div>}
      <style jsx={true}>{styles}</style>
    </form>
  );
};

export default Password;
