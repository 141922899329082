import { Dispatch } from "redux";
import { IFetchUsersBegin, IFetchUsersFailure, IFetchUsersSuccess, IUser } from "../models";
import { FETCH_USER_BEGIN, FETCH_USER_FAILURE, FETCH_USER_SUCCESS } from "../types";
import { getUser } from "../../../services/api";

const fetchUsersBegin = (): IFetchUsersBegin => ({
  type: FETCH_USER_BEGIN,
});

const fetchUsersFailure = (error: string): IFetchUsersFailure => ({
  type: FETCH_USER_FAILURE,
  payload: error,
});

const fetchUsersSuccess = (user: IUser): IFetchUsersSuccess => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});

export default () => async (dispatch: Dispatch) => {
  dispatch(fetchUsersBegin());
  try {
    const user = await getUser();
    dispatch(fetchUsersSuccess(user));
  } catch (e) {
    const { message } = e as Error;
    dispatch(fetchUsersFailure(message));
  }
};
