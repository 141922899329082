import { ISettingsFetchState, IAction } from "../models";
import { GET_SETTINGS_BEGIN, GET_SETTINGS_FAILURE, GET_SETTINGS_SUCCESS, SAVE_SETTINGS_BEGIN, SAVE_SETTINGS_FAILURE, SAVE_SETTINGS_SUCCESS } from "../types";

const initialState: ISettingsFetchState = {
  loading: false,
  saving: false,
  error: null,
  data: { companyName: "", mfa: false, passwordLength: 8, appAutoLogout: 0, domains: [] },
};

export default (state = initialState, action: IAction): ISettingsFetchState => {
  switch (action.type) {
    case GET_SETTINGS_BEGIN:
      return { ...state, loading: true };
    case GET_SETTINGS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_SETTINGS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case SAVE_SETTINGS_BEGIN:
      return { ...state, error: null, saving: true };
    case SAVE_SETTINGS_FAILURE:
      return { ...state, saving: false, error: action.payload };
    case SAVE_SETTINGS_SUCCESS:
      return { ...state, saving: false, data: action.payload };
    default:
      return state;
  }
};
