import { defineMessages } from "react-intl";

const messages = defineMessages({
  back: {
    id: "csvUpload.back",
  },
  next: {
    id: "csvUpload.next",
  },
  preview: {
    id: "csvUpload.preview",
  },
  importAll: {
    id: "csvUpload.importAll",
  },
  importNew: {
    id: "csvUpload.importNew",
  },
  total: {
    id: "csvUpload.total",
  },
  duplicateEmailNumber: {
    id: "csvUpload.email.duplicate.number",
  },
  duplicateEmail: {
    id: "csvUpload.email.duplicate",
  },
  done: {
    id: "csvUpload.done",
  },
  duplicateEmails: {
    id: "csvUpload.duplicateEmails"
  },
  tenantMaxUsersPart0: {
    id: "csvUpload.tenantMaxUser.part0"
  },
  tenantMaxUsersPart1: {
    id: "csvUpload.tenantMaxUser.part1"
  },
  tenantMaxUsersPart2: {
    id: "csvUpload.tenantMaxUser.part2"
  },
  tenantMaxUsersPart3: {
    id: "csvUpload.tenantMaxUser.part3"
  },
  domainForbidden: {
    id: "csvUpload.domainForbidden"
  }
});

export default messages;
