export default {
  "common.copyright": "worX Software Ltd. Registered in England and Wales, company number 12639542",
  "common.signout": "Sign Out",
  "common.error": "Error",
  "common.ok": "Ok",
  "common.cancel": "Cancel",
  "common.progress": "Progress",
  "common.save": "Save",
  "common.success": "Success",
  "common.required": "This field is required!",
  "common.passwordMinLength": "Enter at least 8 characters!",
  "common.contactUs": "Contact us",
};
