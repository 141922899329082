import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Grey_1 },
} = theme;

const styles = css`
  .generalsetting__row {
    height: 89px;
    border-bottom: 1px solid ${Grey_1};
    display: flex;
    align-items: center;
    font-family: Roboto;
  }
  .generalsetting__label {
    width: 410px;
    font-weight: bold;
    font-size: 18px;
  }
`;

export default styles;
