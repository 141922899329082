import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "csvUpload.title",
  },
  configure: {
    id: "csvUpload.configure",
  },
  mapFields: {
    id: "csvUpload.mapFields",
  },
  preview: {
    id: "csvUpload.preview",
  },
  ready: {
    id: "csvUpload.ready",
  },
});

export default messages;
