import React, { useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UserInfoInputModal, SmallButton } from "../";
import { IProps } from "./interfaces";
import { createUser } from "../../services/api/user";
import { IUserInfo } from "../../store/userAdmin/models";
import { Plus } from "../../icons";

const AddUser = ({ updateUserList }: IProps) => {
  const { formatMessage } = useIntl();

  const [isModalOpen, setModalOpen] = useState(false);

  const handleSubmit = useCallback(
    async (params: Partial<IUserInfo>) => {
      const result = await createUser(params);
      if (result.success) {
        updateUserList();
      } else {
        if(result.maxUserError){
          alert(formatMessage({ id: "addUser.maxUserExceeded" }));
        }
        if(result.domainUserError){
          alert(formatMessage({id: "addUser.domainError"}))
        }
      }
    },
    [updateUserList, formatMessage],
  );

  return (
    <>
      {isModalOpen && <UserInfoInputModal title={formatMessage({ id: "addUser.header" })} onSubmit={handleSubmit} close={() => setModalOpen(false)} />}
      <SmallButton type="deep-blue" onClick={() => setModalOpen(true)}>
        <FormattedMessage id={"addUser.headerButtonLabel"} />
        <Plus />
      </SmallButton>
    </>
  );
};

export default AddUser;
