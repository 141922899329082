import React, { useState, useEffect, SyntheticEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import styles from "./styles";
import { loginFactor1, loginFactor2, logout } from "../../services/api";
import TextInput from "../TextInput";
import { validateEmail, validatePassword, validateCode } from "@tagworx/core";
import { useHistory } from "react-router-dom";
import { WorxIcon } from "../../icons";
import { routes } from "../../config";
import { IProps } from "./interfaces";

const LoginComponent = ({ getUser, clearUser }: IProps) => {
  useEffect(() => {
    clearUser();
    logout();
  }, [clearUser]);

  const { formatMessage } = useIntl();
  const history = useHistory();

  const [err, setErr] = useState("");
  const [factor, setFactor] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tenant, setTenant] = useState("");
  const [code, setCode] = useState("");
  const [isPasswordRevealed, setPasswordRevealed] = useState(false);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
  const [tenantError, setTenantError] = useState<string | undefined>(undefined);
  const [codeError, setCodeError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setEmailError(undefined);
  }, [email]);
  useEffect(() => {
    setPasswordError(undefined);
  }, [password]);
  useEffect(() => {
    setTenantError(undefined);
  }, [tenant]);
  useEffect(() => {
    setCodeError(undefined);
  }, [code]);

  const onLoginSuccess = async () => {
    await getUser();
    history.push("/");
  };

  const onLoginFactor1 = async () => {
    if (!tenant || !email || !password) {
      if (!tenant) {
        setTenantError(formatMessage({ id: "common.required" }));
      }
      if (!email) {
        setEmailError(formatMessage({ id: "common.required" }));
      }
      if (!password) {
        setPasswordError(formatMessage({ id: "common.required" }));
      }
      return;
    }
    try {
      const response = await loginFactor1(tenant, email, password);
      if (response.success) {
        if (response.mfa) {
          setFactor(2);
        } else {
          await onLoginSuccess();
        }
      } else {
        if(response.tenantName === 'rateLimiterError' && response.errorMessage && response.errorMessage.length === 3){
          return setErr(`${formatMessage({ id: "login.rateLimiterErrorPart0" })} ${response.errorMessage[0]} ${formatMessage({ id: "login.rateLimiterErrorPart1" })} ${response.errorMessage[1]} ${formatMessage({ id: "login.rateLimiterErrorPart2" })} ${response.errorMessage[2]} ${formatMessage({ id: "login.rateLimiterErrorPart3" })}`);
        }
        if(response.tenantName === 'rateLimiterError' && response.errorMessage){ 
          return setErr(`${formatMessage({ id: "login.userBlocked" })}`);
        }
        setErr(formatMessage({ id: "login.factor1failure" }));
      }
    } catch (err) {
      setErr(err.message);
    }
  };

  const onLoginFactor2 = async () => {
    if (!code) {
      setCodeError(formatMessage({ id: "common.required" }));
      return;
    }
    try {
      const success = await loginFactor2(tenant, email, password, code);
      if (success) {
        await onLoginSuccess();
      } else {
        setCodeError(formatMessage({ id: "login.factor2failure" }));
      }
    } catch (err) {
      setErr(err.message);
    }
  };

  const onSignIn = (event: SyntheticEvent) => {
    event.preventDefault();
    setErr("");
    return factor === 1 ? onLoginFactor1() : onLoginFactor2();
  };

  console.log("We have hit login page");

  return (
    <>
      <div className="login__page">
        <header className="login__worxicon">
          <WorxIcon />
        </header>
        <div className="login__worxdescription">
          <FormattedMessage id="common.copyright" />
        </div>
        <form className="login__form">
          <h1 className="login__header">{factor === 1 ? <FormattedMessage id="login.header1" /> : <FormattedMessage id="login.header2" />}</h1>

          {err && (
            <div className="login__error">
               {err}
            </div>
          )}
          {factor === 1 && (
            <>
              <div className="login__input">
                <TextInput
                  type="email"
                  value={email}
                  placeholder={formatMessage({ id: "login.email" })}
                  description={formatMessage({ id: "login.emaildescription" })}
                  isValid={validateEmail(email)}
                  onChange={(value: string) => setEmail(value)}
                  error={emailError}
                  idFor="login-email"
                />
              </div>

              <div className="login__input">
                <TextInput
                  type={isPasswordRevealed ? "text" : "password"}
                  value={password}
                  placeholder={formatMessage({ id: "login.password" })}
                  isValid={validatePassword(password)}
                  onChange={(value: string) => setPassword(value)}
                  onReveal={() => setPasswordRevealed(!isPasswordRevealed)}
                  error={passwordError}
                  idFor="login-password"
                />
              </div>
              <div className="login__input">
                <TextInput
                  value={tenant}
                  placeholder={formatMessage({ id: "login.tenant" })}
                  description={formatMessage({ id: "login.tenantdescription" })}
                  isValid={!!tenant.length}
                  onChange={setTenant}
                  error={tenantError}
                  idFor="login-tenant-name"
                />
              </div>
              <button type="button" className="login__linkbutton" onClick={() => history.push(routes.passwordReset)}>
                <FormattedMessage id="login.forgotpass" />
              </button>
            </>
          )}

          {factor === 2 && (
            <>
              <div className="login__input">
                <TextInput
                  value={code}
                  placeholder={formatMessage({ id: "login.code" })}
                  description={formatMessage({ id: "login.codedescription" })}
                  isValid={validateCode(code)}
                  onChange={(value: string) => setCode(value)}
                  error={codeError}
                  idFor="login-code"
                />
              </div>
              <button className="login__linkbutton" onClick={() => onLoginFactor1()} type="button">
                <FormattedMessage id="login.smsnotreceived" />
              </button>
            </>
          )}

          {factor === 1 && (
            <button className="login__button login__button--signin" onClick={onSignIn}>
              <FormattedMessage id="login.signin" />
            </button>
          )}

          {factor === 2 && (
            <button className="login__button login__button--signin" onClick={onSignIn} disabled={!validateCode(code)}>
              <FormattedMessage id="login.signin" />
            </button>
          )}

          {factor === 2 && (
            <button
              className="login__button login__button--back"
              type="button"
              onClick={() => {
                setFactor(1);
                setCode("");
              }}
            >
              <FormattedMessage id="login.back" />
            </button>
          )}
        </form>
      </div>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default LoginComponent;
