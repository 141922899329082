import request from "../request";
import { Settings } from "@tagworx/core";

export const getSettings = async (): Promise<Settings> => {
  return request<Settings>({
    endpoint: `settings`,
    config: {
      method: "GET",
    },
  });
};

export const postSettings = async (settings: Partial<Settings>): Promise<Settings> => {
  return request<Settings>({
    endpoint: `settings`,
    config: {
      method: "POST",
      body: JSON.stringify(settings),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};
