import React, { ChangeEvent, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SmallButton } from "..";
import { IProps } from "./interfaces";
import messages from "./messages";

const CsvSelectButton = ({ onSelect }: IProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [inputKey, setInputKey] = useState(1);

  const onButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChange = async (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files?.length) {
      const csv = ev.target.files[0];
      onSelect(csv);
    }
    setInputKey(inputKey + 1);
  };

  return (
    <div key={inputKey}>
      <SmallButton type="deep-blue" onClick={onButtonClick}>
        <label htmlFor="csv" style={{cursor:'pointer'}}><FormattedMessage {...messages.selectFile} /></label>
      </SmallButton>
      <input hidden={true} ref={fileInputRef} type="file" id='csv' name="csv-file" accept=".csv" onChange={onChange} />
    </div>
  );
};
export default CsvSelectButton;
