import React, { useCallback } from "react";
import styles from "./styles";
import { IProps } from "./interfaces";
import { EditUser } from "../..";
import { useIntl } from "react-intl";
import { useCheckPermission } from "../../../hooks";

const placeholder = "-";

const UserAdminTableRow = ({ userInfo, updateList }: IProps) => {
  const { email, role, mobile, firstName, lastName, lastLogin, emailDeleted } = userInfo;

  const { formatDate } = useIntl();
  const writePermission = useCheckPermission("user", "write");
  const getFullName = useCallback(() => {
    if (firstName || lastName) {
      return [firstName, lastName].filter(Boolean).join(" ");
    }
    return placeholder;
  }, [firstName, lastName]);

  return (
    <>
      <tr className="user-admin-table__row">
        <td>{getFullName()}</td>
        <td>{(emailDeleted ? emailDeleted : email) || placeholder}</td>
        <td>{mobile || placeholder}</td>
        <td>{role || placeholder}</td>
        {lastLogin ? <td>{formatDate(new Date(lastLogin), { timeStyle: "medium", dateStyle: "medium" })}</td> : <td></td>}
        {/* <td>
          {!emailDeleted && <EditUser userInfo={userInfo} updateList={updateList} />}
        </td> */}
        {!emailDeleted && writePermission ? (
          <td>
            <EditUser userInfo={userInfo} updateList={updateList} />
          </td>
        ) : (
          <td></td>
        )}
      </tr>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default UserAdminTableRow;
