import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../config";
import styles from "./styles";
import messages from "./messages";
import { FormattedMessage } from "react-intl";

const ProfileSubNav = () => {
  return (
    <>
      <header className="subnavigator__container">
        <h1>
          <FormattedMessage {...messages.myProfile} />
        </h1>
        <ul>
          <li>
            <NavLink activeClassName="active-route" to={`${routes.myProfile}${routes.profileSettings}`}>
              <FormattedMessage {...messages.profileSettings} />
            </NavLink>
          </li>
        </ul>
      </header>
      <style jsx={true}>{styles}</style>
    </>
  );
};
export default ProfileSubNav;
