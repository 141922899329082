import React, { useCallback } from "react";
import styles from "./styles";
import { IProps } from "./interfaces";
import moment from "moment";

const placeholder = "-";

const UserAdminTableRow = ({ userActivityResponse }: IProps) => {

  const { userAffectedId, userExecutorId, action } = userActivityResponse;

  const getFullNameAffected = useCallback(() => {
    if (userAffectedId?.firstName || userAffectedId?.lastName) {
      return [userAffectedId.firstName, userAffectedId.lastName].filter(Boolean).join(" ");
    }
    return placeholder;
  }, [userAffectedId?.firstName, userAffectedId?.lastName]);

  const getFullNameExecutor= useCallback(() => {
    if (userExecutorId?.firstName || userExecutorId?.lastName) {
      return [userExecutorId.firstName, userExecutorId.lastName].filter(Boolean).join(" ");
    }
    return placeholder;
  }, [userExecutorId?.firstName, userExecutorId?.lastName]);

  return (
    <>
      <tr className="user-admin-table__row">
        <td>{moment(userActivityResponse.createdAt).format("DD/MM/YYYY HH:mm")}</td>
        <td>{getFullNameExecutor()}</td>
        <td>{!userExecutorId.emailDeleted ? userExecutorId.email : userExecutorId.emailDeleted}</td>
        <td>{getFullNameAffected()}</td>
        <td>{!userAffectedId?.emailDeleted ? userAffectedId?.email : userAffectedId?.emailDeleted}</td>
        <td>{action || placeholder}</td>
      </tr>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default UserAdminTableRow;
