import css from "styled-jsx/css";
import { theme } from "../../../config";

const {
  colors: { Blue_2, MayaBlue },
} = theme;

const styles = css`
  .user-admin-table__row {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: black;
  }

  .user-admin-table__row:nth-child(odd) {
    background-color: ${Blue_2};
  }
  .user-admin-table__row td {
    padding: 8px 0;
    max-width: 94px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-admin-table__row__link {
    color: inherit;
  }
  .user-admin-table__row td:first-child {
    padding-left: 44px;
  }
  .user-admin-table__row td:last-child {
    padding-right: 19px;
  }
  .user-admin-table__row__index {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    text-decoration-line: underline;
    color: ${MayaBlue};
  }
`;

export default styles;
