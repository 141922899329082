import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "settings.account.menu.changePassword",
  },
  currentPasswordPlaceholder: {
    id: "settings.account.password.current.placeholder",
  },
  newPasswordPlaceholder: {
    id: "settings.account.password.new.placeholder",
  },
  confirmNewPasswordPlaceholder: {
    id: "settings.account.password.confirmNew.placeholder",
  },
  passwordDescription: {
    id: "settings.account.password.description",
  },
  confirm: {
    id: "settings.account.confirm",
  },
  error: {
    id: "common.error",
  },
  success: {
    id: "common.success",
  },
  confirmPasswordError: {
    id: "settings.account.confirm.error",
  },
  required: {
    id: "common.required",
  },
  passwordMinLength: {
    id: "common.passwordMinLength",
  },
});

export default messages;
