import React from "react";
import cn from "classnames";
import styles from "./styles";
import { IProps } from "./interfaces";

const Selector = ({ value, options, onChange }: IProps) => (
  <>
    <div className="selector">
      {options.map((option) => {
        const active = option.value === value;
        return (
          <button type="button" key={option.value} className={cn("selector__option", { "selector__option--active": active })} onClick={() => onChange(option)}>
            {option.label}
          </button>
        );
      })}
    </div>
    <style jsx={true}>{styles}</style>
  </>
);

export default Selector;
