import { defineMessages } from "react-intl";

const messages = defineMessages({
  settings: {
    id: "navbar.settings",
  },
  users: {
    id: "subNavigation.users",
  },
  roles: {
    id: "subNavigation.roles",
  },
  accountSettings: {
    id: "subNavigation.accountSettings",
  },
  userActivity: {
    id: "subNavigation.userActivity"
  },
  generalSettings: {
    id: "subNavigation.generalSettings",
  },
  appSettings: {
    id: "subNavigation.appSettings",
  },
  fieldSettings: {
    id: "subNavigation.fieldSettings",
  },
});

export default messages;
