import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { routes } from "../../config";
import { UserAdmin, Roles, AccountSettings, UserActivity } from "../../containers";
import { useSelector } from "react-redux";
import { useCheckPermission } from "../../hooks";

const SettingsRoute = () => {
  const user = useSelector((state: any) => state.user);
  const options = [
    { path: `${routes.settings}${routes.accountSettings}`, component: AccountSettings, enable: true },
    { path: `${routes.settings}${routes.userAdmin}`, component: UserAdmin, enable: useCheckPermission("user", "read") },
    { path: `${routes.settings}${routes.roles}`, component: Roles, enable: useCheckPermission("role", "read") },
    { path: `${routes.settings}${routes.userActivity}`, component: UserActivity, enable: useCheckPermission("user", "read") },
  ];
  const enabledOptions = options.filter((option: any) => option.enable === true);
  return (
    <Switch>
      {options.map((option: any, i) => option.enable && <Route key={i} path={option.path} component={option.component} />)}
      {enabledOptions.length && <Route path={routes.settings} component={() => <Redirect to={enabledOptions[0].path} />} />}
    </Switch>
  );
};

export default SettingsRoute;
