import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Blue_1, white, Gray_4, Green_1,Blue_4,Green_2 },
} = theme;

export default css`
  button {
    height: 24px;
    padding: 3px 12px 4px;
    border-radius: 4px;
    border: none;
    color: ${white};
    font-size: 14px;
    font-family: Lato;
    outline: none;
    cursor: pointer;
    font-weight: 900;
  }

  button:disabled {
    opacity: 0.7;
    cursor: default;
  }

  .button-type--blue {
    background-color: ${Blue_1};
  }

  .button-type--green {
    background-color: ${Green_1};
  }

  .button-type--deep-blue{
    background-color:${Blue_4}
  }

  .button-type--deep-green{
    background-color:${Green_2}
  }

  .button-type--outline {
    background-color: transparent;
    color: ${Gray_4};
    border: solid 1px ${Gray_4};
  }
`;
