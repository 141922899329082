import React from "react";
import { useIntl } from "react-intl";
import { CsvSelectButton } from "../../../../../../components";
import { IProps } from "./interfaces";
import messages from "./messages";
import styles from "./styles";

export default ({ onSelect }: IProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <h1> {formatMessage(messages.configure)}</h1>
      <div className="select-csv">
        <CsvSelectButton onSelect={onSelect} />
      </div>
      {/*// @ts-ignore */}
      <style jsx={true}>{styles}</style>
    </>
  );
};
