import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SmallButton } from "../../../../../../components";
import { IProps } from "./interfaces";
import messages from "./messages";
import { loaderColor } from "./styles";
import BarLoader from "react-spinners/BarLoader";
import { checkUserItemsCSV, saveUserItemsCSV } from "../../../../../../services/api";
import { BaseResponse } from "@tagworx/core";
import styles from "./styles";

export default ({ onBack, file, fieldMap, onNext, updateUserList, onDone }: IProps) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [totalCount, setTotalCount] = useState(0);
  const [duplicateCount, setDuplicateCount] = useState<string[]>([]);
  const [maxUser, setMaxUser] = useState<BaseResponse>({ success: true })

  useEffect(() => {
    const checkUserItems = async () => {
      try {
        setLoading(true);
        const response = await checkUserItemsCSV(file, fieldMap);
        if(response.domainError) {
          alert(formatMessage(messages.domainForbidden))
          return onDone()
        }
        if (response) {
          setTotalCount(response.totalCount);
          setDuplicateCount(response.duplicateCount);
        }
      } catch (err) {
        alert(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    checkUserItems();
  }, [fieldMap, file]);

  const handleUpload = async (updateExisitng: boolean) => {
    try {
      setLoading(true);
      const response = await saveUserItemsCSV(file, fieldMap, updateExisitng, totalCount - duplicateCount.length);
      if (response.success && response.userCount && response.tenantMaxUser) {
        setMaxUser({
          success: true,
          userCount: response.userCount,
          userCvsCount: totalCount - duplicateCount.length,
          tenantMaxUser: response.tenantMaxUser
        })
        setLoading(false);
      }
      if (response.success && !response.userCount) {
        updateUserList();
        setLoading(false);
        onNext();
      }
    } catch (err) {
      alert(err);
      setError(err);
      onDone()
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1> {formatMessage(messages.preview)}</h1>

      <div className="preview">
        {loading && (
          <div className="loader">
            <BarLoader color={loaderColor} />
          </div>
        )}
        {!loading ? (
          !!error ? (
            <>
              <p>{error.message || "An error occurred"}</p>
            </>
          ) : !maxUser.userCount ? (
            !duplicateCount.length ? (
              <>
                <p>
                  {formatMessage(messages.total)}: {totalCount}
                </p>
                <div className="buttons">
                  <SmallButton type="outline" onClick={onBack}>
                    {formatMessage(messages.back)}
                  </SmallButton>
                  <SmallButton type="blue" onClick={() => handleUpload(true)}>
                    {formatMessage(messages.importAll)}
                  </SmallButton>
                </div>
              </>
            ) : (
              <>
                <p>
                  {formatMessage(messages.total)}: {totalCount}
                </p>
                <p>
                  {formatMessage(messages.duplicateEmailNumber)}: {duplicateCount.length}
                </p>
                <p>
                  {formatMessage(messages.duplicateEmail)}: {duplicateCount.join(", ")}
                </p>
                <p> {formatMessage(messages.duplicateEmails)} </p>
                <div className="buttons">
                  <SmallButton type="blue" onClick={() => handleUpload(true)}>
                    Yes
                  </SmallButton>
                  &nbsp;&nbsp;
                  <SmallButton type="blue" onClick={() => handleUpload(false)}>
                    No
                  </SmallButton>
                </div>
                <div className="buttons">
                  <SmallButton type="outline" onClick={onBack}>
                    {formatMessage(messages.back)}
                  </SmallButton>
                </div>
              </>
            )
          ) :
            <><p>{`${formatMessage(messages.tenantMaxUsersPart0)} ${maxUser.userCount} ${formatMessage(messages.tenantMaxUsersPart1)}${maxUser.tenantMaxUser}${formatMessage(messages.tenantMaxUsersPart2)} ${maxUser.userCvsCount} ${formatMessage(messages.tenantMaxUsersPart3)}`}</p>
              <div className="buttons">
                <SmallButton type="outline" onClick={onBack}>
                  {formatMessage(messages.back)}
                </SmallButton>
                <SmallButton type="blue" onClick={() => onDone()}>
                  {formatMessage(messages.done)}
                </SmallButton>
              </div>
            </>
        ) : (
          <></>
        )}
      </div>
      {/*// @ts-ignore */}
      <style jsx={true}>{styles}</style>
    </>
  );
};
