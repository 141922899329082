import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Gray_3, Blue_1 },
} = theme;

export default css`
  .radio {
    margin-left: 9px;
    width: 18px;
    height: 18px;
    background-color: ${Gray_3};
    border-radius: 9px;
    border: 1px solid #e8eaed;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .radio--selected {
    width: 10px;
    height: 10px;
    background-color: ${Blue_1};
    border-radius: 5px;
  }
`;
