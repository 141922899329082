import { IAction, IRole, IRolesFetchState } from "../models";
import {
  GET_ROLES_BEGIN,
  GET_ROLES_FAILURE,
  GET_ROLES_SUCCESS,
  SAVE_ROLE_BEGIN,
  SAVE_ROLE_FAILURE,
  SAVE_ROLE_SUCCESS,
  DELETE_ROLE_BEGIN,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,
} from "../types";

const initialState: IRolesFetchState = {
  loading: false,
  saving: false,
  error: null,
  data: [],
};

export default (state = initialState, action: IAction): IRolesFetchState => {
  switch (action.type) {
    case GET_ROLES_BEGIN:
      return { ...state, loading: true };
    case GET_ROLES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_ROLES_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case SAVE_ROLE_BEGIN:
      return { ...state, saving: true };
    case SAVE_ROLE_FAILURE:
      return { ...state, saving: false, error: action.payload };
    case SAVE_ROLE_SUCCESS:
      const isNewRole = state.data.findIndex((role) => role._id === action.payload._id) === -1;
      return {
        ...state,
        error: null,
        saving: false,
        data: isNewRole
          ? [...state.data, action.payload]
          : state.data.map((role: IRole) => {
              if (role._id === action.payload._id) {
                return action.payload;
              }
              return role;
            }),
      };
    case DELETE_ROLE_BEGIN:
      return { ...state, saving: true };
    case DELETE_ROLE_FAILURE:
      return { ...state, saving: false, error: action.payload };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        error: null,
        saving: false,
        data: state.data.filter((role: IRole) => {
          if (role._id === action.payload) {
            return false;
          }
          return true;
        }),
      };
    default:
      return state;
  }
};
