import css from "styled-jsx/css";

const styles = css`
  .page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* font-family: Lato; */
  }

  .page__main {
    display: flex;
    flex: 1;
  }

  .page__content {
    width: 100%;
    margin-left: 58px;
  }
`;

export default styles;

export const body = css.global`
  body {
    margin: 0;
    overflow-y: scroll;
  }
`;
