import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "settings.account.menu.changePersonalDetails",
  },
  emailPlaceholder: {
    id: "login.email",
  },
  emailDescription: {
    id: "login.emaildescription",
  },
  phoneNumberPlaceholder: {
    id: "settings.account.phoneNumber.placeholder",
  },
  phoneNumberDescription: {
    id: "settings.account.phoneNumber.description",
  },
  submit: {
    id: "settings.account.save",
  },
  error: {
    id: "common.error",
  },
  success: {
    id: "common.success",
  },
  invalidEmail: {
    id: "invalidEmail",
  },
});

export default messages;
