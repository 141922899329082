import css from "styled-jsx/css";

export default css`
  .select-csv-text {
    max-width: 600px;
    margin-bottom: 30px;
  }

  .select-csv {
    max-width: 600px;
    display: flex;
    justify-content: center;
  }
`;
