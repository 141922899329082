import css from "styled-jsx/css";

const styles = css`
  .header {
    height: 104px;
    width: 100%;
    display: flex;
    align-items: center;

    box-shadow: 0px 4px 10px rgba(202, 202, 202, 0.25);
  }

  .header__title {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    color: black;
  }

  .header__buttons {
    margin-left: auto;
  }
`;

export default styles;
