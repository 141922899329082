import React from "react";
import cn from "classnames";

import styles from "./styles";
import { IProps } from "./interfaces";

const Switcher = ({ active = false, onToggle }: IProps) => (
  <>
    <div className={cn("switcher__container", { "switcher__container--active": active })} onClick={onToggle}>
      <div className={cn("switcher__toggle", { "switcher__toggle--active": active })} />
    </div>
    <style jsx={true}>{styles}</style>
  </>
);

export default Switcher;
