import React from "react";
import Header from "./Header";
import Row from "./Row";
import styles from "./styles";
import { IProps } from "./interfaces";

const UserAdminTable = ({ userList, onUpdate }: IProps) => (
  <>
    <table className="user-admin-table">
      <tbody>
        <Header />
        {userList?.map((userInfo) => (
          <Row key={userInfo._id} userInfo={userInfo} updateList={onUpdate} />
        ))}
      </tbody>
    </table>
    <style jsx={true}>{styles}</style>
  </>
);

export default UserAdminTable;
