export default {
  "formDescriptions.barcode": "e.g.: A1-12345678",
  "formDescriptions.date": "e.g.: 00:00, 12.12.2020.",
  "formDescriptions.mobileNumber": "e.g.: 07123 456 789",
  "formDescriptions.peoplesoftId": "e.g.: JD123456",
  "formDescriptions.firstName": "e.g: John",
  "formDescriptions.lastName": "e.g.: Smith",
  "formDescriptions.email": "e.g.: johndoe@labworx.com",
  "formDescriptions.role": "e.g.: Lab Manager",
};
