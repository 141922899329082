import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styles from "./styles";
import { accountCreate2, validCode } from "../../services/api";
import TextInput from "../TextInput";
import { validatePassword } from "@tagworx/core";
import { useHistory, useLocation } from "react-router-dom";
import { WorxIcon } from "../../icons";
import { routes } from "../../config";
import messages from "./messages";

const AccountCreate = () => {
  const { formatMessage } = useIntl();

  const history = useHistory();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const code = urlParams.get("code") || "";
  const email = urlParams.get("email") || "";
  const tenant = urlParams.get("tenant") || "";

  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const [isPasswordRevealed, setPasswordRevealed] = useState(false);

  const [passwordError, setPasswordError] = useState<string | undefined>(undefined);

  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPasswordError(undefined);
  }, [password]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await validCode(tenant, email, code, "account-create1");
      setIsCodeValid(!!data)
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const onAccountCreate2 = async () => {
    setErr("");
    if (!password) {
      setPasswordError(formatMessage(messages.required));
      return;
    }
    // if (password.length < 6) {
    //   setPasswordError(formatMessage(messages.passwordMinLength));
    //   return;
    // }

    try {
      const success = await accountCreate2(tenant, email, password, code);
      if (success) {
        history.push(routes.login);
      } else {
        setErr(formatMessage(messages.failure));
      }
    } catch (err) {
      setErr(err.message);
    }
  };

  return (
    <>
      
      {
      <div className="accountcreate__page">
        <div className="accountcreate__worxicon">
          <WorxIcon />
        </div>
        <div className="accountcreate__form">
          <div className="accountcreate__header">{!isLoading && !isCodeValid ? 'Invitation expired' :formatMessage(messages.header)}</div>

          {err && (
            <div className="accountcreate__error">
              {formatMessage(messages.error)}: {err}
            </div>
          )}

          <>
          {isLoading && <div className="accountcreate__page">Loading...</div>}  
            {!isLoading && isCodeValid && <><div className="accountcreate__input">
              <TextInput
                type={isPasswordRevealed ? "text" : "password"}
                value={password}
                placeholder={formatMessage(messages.password)}
                // description={formatMessage(messages.passworddescription)}
                isValid={validatePassword(password)}
                onChange={(value: string) => setPassword(value)}
                onReveal={() => setPasswordRevealed(!isPasswordRevealed)}
                error={passwordError}
              />
            </div>
            <button className="accountcreate__button" onClick={() => onAccountCreate2()}>
              {formatMessage(messages.reset)}
            </button></>}
                {!isLoading && !isCodeValid && <div >Your User Invitation link has expired. Please contact your system administrator</div>}
          </>
        </div>
        <div className="accountcreate__worxdescription">{formatMessage(messages.copyright)}</div>
      </div>}

      <style jsx={true}>{styles}</style>
    </>
  );
};

export default AccountCreate;
