import { Dispatch } from "redux";
import { IGetRolesBegin, IGetRolesFailure, IGetRolesSuccess, IRole } from "../models";
import { GET_ROLES_BEGIN, GET_ROLES_FAILURE, GET_ROLES_SUCCESS } from "../types";
import { getRoles } from "../../../services/api";

const getRolesBegin = (): IGetRolesBegin => ({
  type: GET_ROLES_BEGIN,
});

const getRolesFailure = (error: string): IGetRolesFailure => ({
  type: GET_ROLES_FAILURE,
  payload: error,
});

const getRolesSuccess = (roles: IRole[]): IGetRolesSuccess => ({
  type: GET_ROLES_SUCCESS,
  payload: roles,
});

export default () => async (dispatch: Dispatch) => {
  dispatch(getRolesBegin());
  try {
    const roles = await getRoles();
    dispatch(getRolesSuccess(roles));
  } catch (e) {
    const { message } = e as Error;
    dispatch(getRolesFailure(message));
  }
};
