import { Dispatch } from "redux";
import { ISaveSettingsBegin, ISaveSettingsFailure, ISaveSettingsSuccess, ISettings } from "../models";
import { SAVE_SETTINGS_BEGIN, SAVE_SETTINGS_FAILURE, SAVE_SETTINGS_SUCCESS } from "../types";
import { postSettings } from "../../../services/api";

const getSettingsBegin = (): ISaveSettingsBegin => ({
  type: SAVE_SETTINGS_BEGIN,
});

const getSettingsFailure = (error: string): ISaveSettingsFailure => ({
  type: SAVE_SETTINGS_FAILURE,
  payload: error,
});

const getSettingsSuccess = (settings: ISettings): ISaveSettingsSuccess => ({
  type: SAVE_SETTINGS_SUCCESS,
  payload: settings,
});

export default (settings: ISettings) => async (dispatch: Dispatch) => {
  dispatch(getSettingsBegin());
  try {
    const saved = await postSettings(settings);
    dispatch(getSettingsSuccess(saved));
  } catch (e) {
    const { message } = e as Error;
    dispatch(getSettingsFailure(message));
  }
};
