import React from "react";
import { FormattedMessage } from "react-intl";
import { Close, Check, Edit } from "../icons";
import { IProps } from "./interfaces";
import styles from "./styles";
import messages from "./messages";

const RoleCard = ({ role, preview, onEdit, writePermission }: IProps) => (
  <>
    <div className="role-card" key={role._id}>
      <div className="role-card__top">
        <div className="role-card__name">{role.name}</div>
        {!preview && !role.readOnly && writePermission && (
          <button className="role-card__edit" title="edit role" onClick={() => onEdit!(role)}>
            <Edit />
          </button>
        )}
      </div>
      {Object.keys(role.permissions).map((permission) => {
        const label = permission.slice(0, 1).toUpperCase() + permission.slice(1);
        let rolePermission = { read: false, write: false };
        if (role.permissions) {
          rolePermission = role.permissions[permission] || { read: false, write: false };
        }
        return (
          <div key={permission} className="permission">
            <div className="permission__name">{label}</div>
            <div className="permission__commands">
              {rolePermission.read ? <Check /> : <Close />} <FormattedMessage {...messages.view} />
              {rolePermission.write ? <Check /> : <Close />} <FormattedMessage {...messages.edit} />
            </div>
          </div>
        );
      })}
    </div>
    <style jsx={true}>{styles}</style>
  </>
);

export default RoleCard;
