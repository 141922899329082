import css from "styled-jsx/css";

export default css`
  .select-csv-text {
    max-width: 600px;
    margin-bottom: 30px;
  }

  .select-csv {
    max-width: 600px;
    display: flex;
    justify-content: center;
  }

  .map-fields {
    display: flex;
  }

  .fields-column {
    width: 600px;
  }

  .map-field-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin: 20px;
  }

  .map-field-dropdown {
    width: 300px;
  }

  .buttons {
    display: flex;
    margin-top: 28px;
    margin-bottom: 24px;
  }
  .buttons :global(button) {
    margin-left: 8px;
    width: 69px;
  }
`;
