import React, { useEffect, useState } from "react";
import { Page, SubNavigator, AddUser, UserAdminTable, SmallButton, DatePickerFilter } from "../../components";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { IStateTree } from "../../store/interfaces";
import { getUserList } from "../../store/userAdmin/actions";
import styles from "./styles";
import { getSettings } from "../../store/settings/actions";
import { useIntl } from "react-intl";
import CsvImport from "../User/CsvImport";
import { IconContext } from "react-icons";
import { FiDownload } from "react-icons/fi";
import CsvDownloadButton from "../../components/CsvDownloadButton";
import { UserQueryAttributes } from "@tagworx/core";
import { useCheckPermission } from "../../hooks";

const UserAdmin = () => {
  const dispatch = useDispatch();
  const userAdminState = useSelector(({ userAdmin }: IStateTree) => userAdmin);
  const settingsState = useSelector(({ settings }: IStateTree) => settings);
  const [importCsv, setImportCsv] = useState(false);
  const [count, setCount] = useState(0);
  const [changeCurr, setChangeCurr] = useState(true);
  const [filters, setFilters] = useState<{ blocked?: boolean; lastLogin?: Date }>({});
  const writePermission = useCheckPermission("user", "write");

  const query: UserQueryAttributes = {
    sort: "email",
  };

  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(getUserList({}));
    dispatch(getSettings());
  }, [dispatch]);

  const updateList = () => {
    dispatch(getUserList({}));
    setChangeCurr(true);
  };

  useEffect(() => {
    if (!userAdminState.loading && changeCurr && userAdminState.data.length > 0) {
      setCount(userAdminState.data.length);
      setChangeCurr(false)
    }
  }, [userAdminState.data]);

  return (
    <Page>
      {!importCsv && (
        <div className="page-container">
          <SubNavigator page="settings" />
          <div className={cn("user-table__container", { "user-table__container--loading": userAdminState.loading })}>
            <div className="user-table__top">
              {settingsState.data && settingsState.data.maxUsers && (
                <div>
                  {formatMessage(
                    { id: "userAdminTable.maxLimit" },
                    {
                      max: settingsState.data.maxUsers,
                      curr: count ? count : userAdminState.data.length,
                      contactUs: (
                        <a href="https://worx.software/contact" target="_blank" rel="noopener noreferrer">
                          {formatMessage({ id: "common.contactUs" })}
                        </a>
                      ),
                    },
                  )}{" "}
                </div>
              )}
              {writePermission && (
                <div className={cn("user-table__container__buttons", { "user-table__container_buttons--loading": userAdminState.loading })}>
                  <div className="user-info-input__input"></div>

                  <SmallButton type="blue" onClick={() => setImportCsv(true)}>
                    <span className="icon-text">{formatMessage({ id: "csvUpload.import" })}</span>
                    <IconContext.Provider value={{ color: "#FFFFFF" }}>
                      <FiDownload />
                    </IconContext.Provider>
                  </SmallButton>
                  <CsvDownloadButton query={query} type="button" />
                  <AddUser updateUserList={updateList} />
                </div>
              )}
            </div>
            <UserAdminTable userList={userAdminState.data} onUpdate={updateList} />
          </div>
        </div>
      )}
      {importCsv && <CsvImport fragment={"fragment"} close={() => setImportCsv(false)} updateUserList={updateList} />}
      <style jsx={true}>{styles}</style>
    </Page>
  );
};

export default UserAdmin;
