import React, { useCallback } from "react";
import ReactDropdown, { Option } from "react-dropdown";
import cn from "classnames";
import "react-dropdown/style.css";
import { ChevronDown } from "../../icons";
import { IProps, IOption } from "./interfaces";
import styles from "./styles";

const Dropdown = ({ options, value, onChange, placeholder, description, disabled }: IProps) => {
  const getOptionObject = useCallback((option: IOption) => ({ ...option, className: cn("dropdown__menu__option", { "dropdown__menu__option--active": value === option.value }) }), [
    value,
  ]);
  const handleChange = useCallback(
    (option: Option) => {
      onChange(option.value);
    },
    [onChange],
  );
  return (
    <>
      <div className="dropdown">
        <ReactDropdown
          value={value}
          options={options.map((option) => getOptionObject(option))}
          controlClassName={cn("dropdown__toggle-btn", { "dropdown__toggle-btn--selected": value })}
          placeholderClassName="dropdown__placeholder"
          menuClassName="dropdown__menu"
          arrowClosed={<ChevronDown />}
          arrowOpen={<ChevronDown />}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
        />
        {placeholder && value && <div className="dropdown__label">{placeholder}</div>}
        {description && <div className="text-description">{description}</div>}
      </div>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default Dropdown;
