import css from "styled-jsx/css";
import { theme } from "../../../config";

const {
  colors: { Blue_4 },
} = theme;

export default css`
  .container {
    margin: 29px 32px;
    display: flex;
  }

  .editor {
    width: 612px;
  }

  .preview {
    width: 235px;
    margin-left: 83px;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: none;
  }

  .top-row {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  .top-row__left {
    display: flex;
  }

  h2,legend {
    margin: 0;
    line-height: 22px;
    font-size: 18px;
    font-weight: 900;
  }
  .back {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 8px;
    padding: 0;
  }

  .back :global(svg) {
    display: block;
  }
  h1{
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 11px;
    margin-top: 34px;
  }
  h3 {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 11px;
    margin-top: 34px;
  }
  .permission {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
  .permission-name {
    flex: 1;
    font-size: 12px;
    line-height: 14px;
  }
  .toggle {
    display: flex;
    align-items: center;
  }
  .toggle-name {
    margin-right: 24px;
    margin-left: 80px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 900;
  }

  .delete-button {
    background-color: transparent;
    color: ${Blue_4};
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: Roboto;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
    margin-top: 5px;
  }

  .delete-button:disabled {
    cursor: default;
  }
`;
