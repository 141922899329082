import * as React from "react";
import { Provider as ReactReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { IProps } from "./interfaces";
import { persistor, store } from "./store";

const Provider = ({ children }: IProps) => (
  <ReactReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  </ReactReduxProvider>
);

export default Provider;
