import { Application } from "@tagworx/core";
import { InventoryIcon } from "../icons";

const isProduction = process.env.NODE_ENV === "production";

interface ExternalNav {
  id: Application;
  url: string;
  icon: JSX.Element;
  tooltipId: string;
}

export const externalApplications: ExternalNav[] = [
  {
    id: Application.inventory,
    url: isProduction ? `/${Application.inventory}` : "http://localhost:3011",
    icon: InventoryIcon(),
    tooltipId: "navbar.inventory",
  },
];
