import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Grey_1, Grey_3, MayaBlue },
} = theme;
const styles = css`
  .progress {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    color: ${Grey_3};
  }

  .progress-label__right {
    float: right;
    color: ${MayaBlue};
  }
  .progress-bar-container {
    width: 100%;
    height: 7px;
    background: ${Grey_1};
    border-radius: 3.5px;
  }
  .progress-bar {
    height: 7px;
    background: ${MayaBlue};
    border-radius: 3.5px;
  }
`;

export default styles;
