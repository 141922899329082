import { Dispatch } from "redux";
import { ISaveRoleBegin, ISaveRoleFailure, ISaveRoleSuccess, IRole } from "../models";
import { SAVE_ROLE_BEGIN, SAVE_ROLE_FAILURE, SAVE_ROLE_SUCCESS } from "../types";
import { saveRole } from "../../../services/api";

const saveRoleBegin = (): ISaveRoleBegin => ({
  type: SAVE_ROLE_BEGIN,
});

const saveRoleFailure = (error: string): ISaveRoleFailure => ({
  type: SAVE_ROLE_FAILURE,
  payload: error,
});

const saveRoleSuccess = (updatedRole: IRole): ISaveRoleSuccess => ({
  type: SAVE_ROLE_SUCCESS,
  payload: updatedRole,
});

export default (role: IRole) => async (dispatch: Dispatch) => {
  dispatch(saveRoleBegin());
  try {
    const updatedRole = await saveRole(role);
    dispatch(saveRoleSuccess(updatedRole));
  } catch (e) {
    const { message } = e as Error;
    dispatch(saveRoleFailure(message));
  }
};
