import css from "styled-jsx/css";

export default css`
  .container {
    margin: 41px 22px 41px 25px;
  }

  .top-row {
    display: flex;
    justify-content: flex-end;
  }

  .role-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 26px;
    grid-row-gap: 26px;
    margin-top: 23px;
  }

  .top-row :global(button) {
    margin-top: 5px;
    display: flex;
    padding-left: 8px;
    width: 108px;
  }

  .top-row :global(button svg) {
    margin-right: 5px;
  }
`;
