import React, { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles";
import { passwordReset1, passwordReset2, validCode } from "../../services/api";
import TextInput from "../TextInput";
import { validateEmail, validatePassword } from "@tagworx/core";
import { useHistory, useLocation } from "react-router-dom";
import { WorxIcon } from "../../icons";
import { routes } from "../../config";

const PasswordReset = () => {
  const { formatMessage } = useIntl();

  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const code = searchParams.get("code") || "";
  const factor = searchParams.has("email") ? 2 : 1;
  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [password, setPassword] = useState("");
  const [tenant, setTenant] = useState(searchParams.get("tenant") || "");
  const [err, setErr] = useState("");
  const [isPasswordRevealed, setPasswordRevealed] = useState(false);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [tenantError, setTenantError] = useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
    const fetchData = async () => {
      const data = await validCode(tenant, email, code, "password-reset1");
      setIsCodeValid(!!data)
      setIsLoading(false)
    }                        
  
    fetchData()
  }, [])

  useEffect(() => {
    setEmailError(undefined);
  }, [email]);
  useEffect(() => {
    setTenantError(undefined);
  }, [tenant]);
  useEffect(() => {
    setPasswordError(undefined);
  }, [password]);

  const onPasswordReset1 = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!email || !tenant) {
      if (!email) {
        setEmailError(formatMessage({ id: "common.required" }));
      }
      if (!tenant) {
        setTenantError(formatMessage({ id: "common.required" }));
      }
      return;
    }
    setErr("");
    try {
      await passwordReset1(tenant, email);
      alert(formatMessage({ id: "passwordReset.emailsent" }));
      history.push(routes.login);
    } catch (err) {
      setErr(err.message);
    }
  };

  const onPasswordReset2 = async () => {
    setErr("");
    if (!password) {
      setPasswordError(formatMessage({ id: "common.required" }));
      return;
    }
    // if (password.length < 8) {
    //   setPasswordError(formatMessage({ id: "common.passwordMinLength" }));
    //   return;
    // }
    const urlParams = new URLSearchParams(window.location.search);
    const queryTenant = urlParams.get("tenant");

    if (!queryTenant) {
      alert(formatMessage({ id: "passwordReset.tenantMissingFromURL" }));
      return;
    }

    try {
      const success = await passwordReset2(queryTenant, email, password, code);
      if (success) {
        history.push(routes.login);
      } else {
        setErr(formatMessage({ id: "passwordReset.failure" }));
      }
    } catch (err) {
      setErr(err.message);
    }
  };

  return (
    <>
      <div className="passwordreset__page">
        <header className="passwordreset__worxicon">
          <WorxIcon />
        </header>
        <div className="passwordreset__form">
          <h1 className="passwordreset__header">
            {" "}
            <FormattedMessage id="passwordReset.header" />{" "}
          </h1>

          {err && (
            <div className="passwordreset__error">
             {err}
            </div>
          )}
          {factor === 1 && (
            <form>
              <div className="passwordreset__input">
                <TextInput
                  type="email"
                  value={email}
                  placeholder={formatMessage({ id: "login.email" })}
                  description={formatMessage({ id: "login.emaildescription" })}
                  isValid={validateEmail(email)}
                  onChange={(value: string) => setEmail(value)}
                  error={emailError}
                  idFor="reset-email"
                />
              </div>
              <div className="passwordreset__input">
                <TextInput
                  value={tenant}
                  placeholder={formatMessage({ id: "login.tenant" })}
                  description={formatMessage({ id: "login.tenantdescription" })}
                  isValid={!!tenant.length}
                  onChange={setTenant}
                  error={tenantError}
                  idFor="reset-tenant"
                />
              </div>

              <button className="passwordreset__button passwordreset__button--signin" onClick={onPasswordReset1}>
                <FormattedMessage id="passwordReset.request" />
              </button>
            </form>
          )}

          {factor === 2 && (
            <>
              {!isLoading && isCodeValid && <> <div className="passwordreset__input">
                <TextInput
                  type={isPasswordRevealed ? "text" : "password"}
                  value={password}
                  placeholder={formatMessage({ id: "passwordReset.password" })}
                  // description={formatMessage({ id: "login.passworddescription" })}
                  isValid={validatePassword(password)}
                  onChange={(value: string) => setPassword(value)}
                  onReveal={() => setPasswordRevealed(!isPasswordRevealed)}
                  error={passwordError}
                  idFor="reset-new-password"
                />
              </div>
              <button className="passwordreset__button" onClick={() => onPasswordReset2()}>
                <FormattedMessage id="passwordReset.reset" />
              </button></>}
              {!isLoading && !isCodeValid && <div>Your Password Reset invitation expired. Please contact your system administrator.</div>}
            </>
          )}
        </div>
        <div className="passwordreset__worxdescription">
          <FormattedMessage id="common.copyright" />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default PasswordReset;
