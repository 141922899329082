import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "classnames";
import { TextInput, Switcher, SmallButton } from "../../../components";
import styles from "./styles";
import messages from "./messages";
import { useDispatch, useSelector } from "react-redux";
import { IStateTree } from "../../../store/interfaces";
import { getSettings, saveSettings } from "../../../store/settings/actions";
import { usePrevious } from "../../../util";
import { useForm } from "react-hook-form";
import { Popover } from "antd";
import { theme } from "../../../config/theme";
import { AiFillQuestionCircle } from "react-icons/ai";
import { DeleteIcon } from "../../../icons";

const PersonalDetails = () => {
  const regexp = /^\d+\.\d{0,2}$/;
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const { formatMessage } = useIntl();
  const {
    saving,
    error,
    data,
    //   : { mfa, companyName, passwordLength, appAutoLogout, domains, lockUsersDays },
  } = useSelector(({ settings }: IStateTree) => settings);
  const userData = useSelector((state: any) => state.user);
  const wasSaving = usePrevious(saving);

  useEffect(() => {
    if (!error && wasSaving) {
      setSuccess(true);
    }
  }, [wasSaving, error]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [company, setCompany] = useState(data?.companyName ? data.companyName : "");
  const [companyError, setCompanyError] = useState<string | undefined>(undefined);
  const [multiFactor, setMultiFactor] = useState(!!data?.mfa);
  const [passwordLengthValue, setPasswordLengthValue] = useState(data?.passwordLength);
  const [loggoutValue, setLoggoutValue] = useState(data?.appAutoLogout);
  const [newDomain, setNewDomain] = useState("");
  const [formDomains, setFormDomains] = useState(data?.domains ? data.domains : []);
  const [lockDays, setLockDays] = useState<number>(data?.lockUsersDays || 0);

  useEffect(() => {
    setCompanyError(undefined);
  }, [company]);

  useEffect(() => {
    setMultiFactor(!!data?.mfa);
  }, [data?.mfa]);

  useEffect(() => {
    setCompany(data?.companyName);
  }, [data?.companyName]);

  useEffect(() => {
    setPasswordLengthValue(data?.passwordLength);
  }, [data?.passwordLength]);
  useEffect(() => {
    setLoggoutValue(data?.appAutoLogout);
  }, [data?.appAutoLogout]);
  const handleToggleMultiFactor = useCallback(() => {
    setMultiFactor((oldValue) => !oldValue);
  }, [setMultiFactor]);

  useEffect(() => {
    setFormDomains(data?.domains ? data?.domains : []);
  }, [data?.domains]);

  useEffect(() => {
    setLockDays(data?.lockUsersDays ? data?.lockUsersDays : 0);
  }, [data?.lockUsersDays]);

  const handleSaveSettings = () => {
    setSuccess(false);

    if (!company) {
      setCompanyError(formatMessage(messages.required));
      return;
    }

    dispatch(
      saveSettings({
        mfa: multiFactor,
        companyName: company,
        passwordLength: Number(passwordLengthValue),
        appAutoLogout: Number(loggoutValue),
        domains: formDomains,
        lockUsersDays: lockDays || 0,
      }),
    );
  };

  const onSubmit = () => {
    handleSaveSettings();
  };

  const passwordLengthChange = (length: number) => {
    setPasswordLengthValue(length);
  };
  const logoutLengthChange = (length: number) => {
    setLoggoutValue(length);
  };

  const lockUserChange = (days: number) => {
    setLockDays(days);
  };
  const popContent = (text: string) => {
    return <div className="popOver">{text}</div>;
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <h2>
        <FormattedMessage {...messages.title} />
      </h2> */}
      <div className="company-spacer">
        <TextInput
          value={company}
          error={companyError}
          placeholder={formatMessage(messages.companyPlaceholder)}
          description={formatMessage(messages.companyDescription)}
          onChange={setCompany}
          idFor="company"
        />
      </div>
      <div className="multi-factor-spacer ">
        <span id="#multiFactor">
          <FormattedMessage {...messages.multiFactor} />
        </span>
        <Switcher active={multiFactor} onToggle={handleToggleMultiFactor} />
      </div>
      <h2>
        <FormattedMessage {...messages.restrictUserDomainTitle} />
      </h2>
      <div className="multi-factor-spacer">
        <div className="popover-input">
          <div className="company-Url">
            <label htmlFor="company-Url">Enter URL</label>
            <div className={"text-input text-input__content"}>
              <div className="text-input__content">
                <input value={newDomain} placeholder="company.com" id="company-Url" className="text-input__input" onChange={(e) => setNewDomain(e.target.value)} />
              </div>
            </div>
          </div>
          <Popover placement="bottom" content={popContent(formatMessage(messages.restrictUserDomainHelp))}>
            <div className="button-mr text-[15px]">
              <AiFillQuestionCircle size={15} color={theme.colors.Blue_1} style={{ top: "16px", position: "relative", cursor: "pointer", left: "5px" }} />
            </div>
          </Popover>
        </div>
        {userData?.data?.role === "Admin" && (
          <SmallButton
            type={"green"}
            onClick={(event) => {
              event?.preventDefault();
              if (newDomain.length < 1) {
                return alert("Please enter a valid domain");
              }
              setFormDomains([...formDomains, ...[newDomain]]);
              setNewDomain("");
            }}
          >
            Add domain
          </SmallButton>
        )}
      </div>
      <div className="multi-factor-spacer">
        {formDomains && formDomains.length > 0 ? (
          <div>
            <label>
              <FormattedMessage {...messages.domainPermitted} />
            </label>
            <div>
              {formDomains.map((domain: string, index: number) => (
                <div key={index}>
                  {domain}
                  <button
                    className="edit-user__button"
                    onClick={() => {
                      window.confirm(formatMessage(messages.deleteDomainConfirm)) && setFormDomains(formDomains.filter((item: string) => item !== domain));
                    }}
                  >
                    {" "}
                    <DeleteIcon />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className="multi-factor-spacer">
        <label htmlFor="password-policy">
          <FormattedMessage {...messages.passwordPolicy} />
          {/*// @ts-ignore */}
          {errors.passwordLength && errors.passwordLength.type === "moreThanSevenNumber" && (
            <div className="text-description--error">{formatMessage(messages.moreThanSevenNumberError)}</div>
          )}
          {/*// @ts-ignore */}
          {errors.passwordLength && errors.passwordLength.type === "required" && <div className="text-description--error">{formatMessage(messages.requiredError)}</div>}
          {/*// @ts-ignore */}
          {errors.passwordLength && errors.passwordLength.type === "noDecimal" && <div className="text-description--error">{formatMessage(messages.noDecimalError)}</div>}
        </label>
        <div className="password-policy-input-wrapper">
          <div className={cn("text-input", { "text-input--error": !!errors.passwordLength })}>
            <div className="text-input__content">
              <input
                value={passwordLengthValue}
                placeholder="8"
                type="number"
                className="text-input__input"
                {...register("passwordLength", {
                  required: "(required)",
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    passwordLengthChange((e.target.value as unknown) as number);
                  },
                  validate: {
                    moreThanSevenNumber: (value) => parseFloat(value) > 7,
                    noDecimal: (value) => !regexp.test(value),
                  },
                })}
                min="0"
                id="password-policy"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="multi-factor-spacer">
        <label htmlFor="auto-logout">
          <FormattedMessage {...messages.apploggout} />
        </label>
        <span>
          <Popover placement="top" content={popContent(formatMessage(messages.apploggouttext))}>
            <div className="button-mr text-[15px]">
              <AiFillQuestionCircle size={15} color={theme.colors.Blue_1} style={{ top: "4px", position: "relative", cursor: "pointer" }} />
            </div>
          </Popover>
        </span>

        <div className="password-policy-input-wrapper">
          <div className={cn("text-input", { "text-input--error": "" })}>
            <div className="text-input__content">
              <input
                value={loggoutValue}
                placeholder="5"
                type="number"
                className="text-input__input"
                {...register("loggoutValue", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    // console.log("e auto", e.target.value);
                    logoutLengthChange((e.target.value as unknown) as number);
                  },
                })}
                min="0"
                id="auto-logout"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="multi-factor-spacer">
        <label htmlFor="auto-logout">
          <FormattedMessage {...messages.lockUsersDays} />
        </label>
        <span>
          <Popover placement="top" content={popContent(formatMessage(messages.lockUserDaysText))}>
            <div className="button-mr text-[15px]">
              <AiFillQuestionCircle size={15} color={theme.colors.blue_4} style={{ top: "4px", position: "relative", cursor: "pointer" }} />
            </div>
          </Popover>
        </span>

        <div className="password-policy-input-wrapper">
          <div className={cn("text-input", { "text-input--error": "" })}>
            <div className="text-input__content">
              <input
                value={lockDays}
                placeholder=""
                type="number"
                className="text-input__input"
                {...register("expValue", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    lockUserChange((e.target.value as unknown) as number);
                  },
                })}
                min="0"
                id="auto-logout"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          {loggoutValue < 5 && loggoutValue >= 1 ? (
            <label>
              <div className="text-description--error">
                {" "}
                <FormattedMessage {...messages.moreThanFiveError} />
              </div>
            </label>
          ) : (
            ""
          )}
        </div>
        {userData?.data?.role === "Admin" && (
          <button type="submit" className="button-type--deep-blue">
            <FormattedMessage {...messages.confirm} />
          </button>
        )}
      </div>
      {/* {error && <div className={cn("message", { error: !!error })}>{error}</div>} */}
      {success && <div className={cn("message")}>{formatMessage(messages.success)}</div>}
      <style jsx={true}>{styles}</style>
    </form>
  );
};

export default PersonalDetails;
