import css from "styled-jsx/css";
import { theme } from "../../config/";

const {
  colors: { MayaBlue,Blue_4,Grey_4, Blue_1, Grey_3, Red_1 },
} = theme;

const styles = css`
  .edit-user__buttons {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${MayaBlue};
    float: right;
  }

  .edit-user__button {
    margin-left: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${Blue_4};
  }

  .edit-user__buttons__resetPassword {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${Grey_4};
    cursor: pointer;
  }

  .edit-user__buttons__activatedAt {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    color: ${Red_1};
  }
  .edit-user__button__disabled {
    cursor: not-allowed;
  }
`;

export default styles;
