export const routes = {
  login: "/login",
  passwordReset: "/passwordreset",
  accountCreate: "/accountcreate",
  settings: "/settings",
  roles: "/roles",
  accountSettings: "/tenant-settings",
  changePersonalDetails: "/change-personal-details",
  changePassword: "/change-password",
  changeCompanyDetails: "/change-company-details",
  userAdmin: "/useradmin",
  userActivity: "/useractivity",
  myProfile: "/my-profile",
  profileSettings: "/profile-settings",
  generalSettings: "/general-settings",
  appSettings: "/app-settings",
  editTable: "/field-settings",
};
