import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "settings.account.menu.changeCompanyDetails",
  },
  confirm: {
    id: "settings.account.confirm",
  },
  companyDescription: {
    id: "settings.account.company.description",
  },
  companyPlaceholder: {
    id: "settings.account.company.placeholder",
  },
  multiFactor: {
    id: "settings.account.multiFactor",
  },
  success: {
    id: "common.success",
  },
  required: {
    id: "common.required",
  },
  passwordPolicy: {
    id: "settings.account.passwordPolicy",
  },
  apploggout: {
    id: "settings.account.apploggout",
  },
  apploggouttext: {
    id: "settings.account.apploggouttext",
  },
  requiredError: {
    id: "settings.account.passwordPolicy.requiredError",
  },
  moreThanSevenNumberError: {
    id: "settings.account.passwordPolicy.moreThanSevenNumber",
  },
  moreThanFiveError: {
    id: "settings.account.moreThanFiveNumber",
  },
  noDecimalError: {
    id: "settings.account.passwordPolicy.noDecimalError",
  },
  domainPermitted:{id: "settings.account.domainPermitted"},
  restrictUserDomainTitle:{id: "settings.account.restrictUserDomainTitle"},
  restrictUserDomainHelp: {id: "settings.account.restrictUserDomainHelp"},
  deleteDomainConfirm : {id: "settings.account.deleteDomainConfirm"},
  lockUsersDays: {
    id: "settings.account.lockUsersDays",
  },
  lockUserDaysText: {
    id: "settings.account.lockUserDaysText",
  },
});

export default messages;
