const colors = {
  Comet: "#585D6E",
  PalePurple: "#B69EEC",
  MayaBlue: "#52C4F5",
  DarkMayaBlue: "#185E7C",
  MidMayaBlue: "#045F86",
  Grey_3: "#A5ADBA",
  Grey_4:"#49505F",

  Blue_1: "#4686FA",
  Blue_2: "#EDF3FF",
  Blue_3: "#041E4F",
  Blue_6: "#12004A",
  Blue_7: "#289CFF",
  Blue_4: "#054BCC",
  Blue_8:"#0047e0",
  Gray_1: "#989CA3",
  Gray_2: "#E8EAED",
  Gray_3: "#FAFBFD",
  Gray_4: "#565961",
  Gray_5: "#2A2F41",
  Grey_1: "#E8EAED",
  Green_1: "#2DC57C",
  Green_2:"#166440",
  Red_1: "#E34343",
  blue_4: "#9AB2DE",

  white: "#FFFFFF",
  black: "#000000",
};

export const theme = {
  colors,
};
