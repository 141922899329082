import React from "react";
import { IntlProvider } from "react-intl";
import locales from "./locales";
import Router from "./routes";
import "typeface-roboto";
import "typeface-lato";
import { BrowserRouter } from "react-router-dom";
import { ActivityWatcherContainer } from "./containers";

const App = () => (
  <IntlProvider locale="en-gb" messages={locales.en} defaultLocale="en-gb">
    <BrowserRouter>
      <ActivityWatcherContainer>
        <Router />
      </ActivityWatcherContainer>
    </BrowserRouter>
  </IntlProvider>
);

export default App;
