import css from "styled-jsx/css";
import { theme } from "../../config/theme";

const {
  colors: { white },
} = theme;

const styles = css`
  .user-info-input__container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 482px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    background-color: ${white};
    padding: 30px 26px;
    overflow-y: auto;
    z-index: 1;
  }

  .user-info-input__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  h1 {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
  }

  .user-info-input__action-buttons {
    display: flex;
  }
  .user-info-input__action-buttons :global(button) {
    margin-left: 8px;
    width: 69px;
    display: block;
    margin-bottom: 0;
  }

  .user-info-input__input {
    margin-bottom: 17px;
  }
`;

export default styles;
