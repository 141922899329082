import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles";

const UserAdminTableHeader = () => {

  return (
    <>
      <tr className="user-admin-table__header">
        <th>
          <FormattedMessage id="userActivityTable.date" />
        </th>
        <th>
          <FormattedMessage id="userActivityTable.initiatedByUser" />
        </th>
        <th>
          <FormattedMessage id="userActivityTable.initiatedByEmail" />
        </th>
        <th>
          <FormattedMessage id="userActivityTable.userAffectedUser" />
        </th>
        <th>
          <FormattedMessage id="userActivityTable.userAffectedEmail" />
        </th>
        <th>
          <FormattedMessage id="userActivityTable.action" />
        </th>
      </tr>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default UserAdminTableHeader;
