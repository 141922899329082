import login from "./login";
import common from "./common";
import passwordReset from "./passwordReset";
import addUser from "./addUser";
import formDescriptions from "./formDescriptions";
import formPlaceholders from "./formPlaceholders";
import roles from "./roles";
import editUser from "./editUser";
import userAdminTable from "./userAdminTable";
import adminHeader from "./adminHeader";
import navbar from "./navbar";
import formValidationError from "./formValidationError";
import generalSettings from "./generalSettings";
import subNavigation from "./subNavigation";
import accountSettings from "./accountSettings";
import accountCreate from "./accountCreate";
import csvImport from "./csvImport";
import csvDOwnload from "./csvDownload";
import userActivityTable from "./userActivityTable";
import profileSettings from "./profileSettings";


export default {
  ...login,
  ...common,
  ...passwordReset,
  ...addUser,
  ...formDescriptions,
  ...formPlaceholders,
  ...roles,
  ...editUser,
  ...userAdminTable,
  ...adminHeader,
  ...navbar,
  ...formValidationError,
  ...generalSettings,
  ...subNavigation,
  ...accountSettings,
  ...accountCreate,
  ...csvImport,
  ...csvDOwnload,
  ...userActivityTable,
  ...profileSettings
};
