import { Dispatch } from "redux";
import { IUserInfo, IFetchUserListBegin, IFetchUserListFailure, IFetchUserListSuccess } from "../models";
import { FETCH_USER_LIST_SUCCESS, FETCH_USER_LIST_FAILURE, FETCH_USER_LIST_BEGIN } from "../types";
import { getUserList } from "../../../services/api";

export const fetchUserListBegin = (): IFetchUserListBegin => ({
  type: FETCH_USER_LIST_BEGIN,
});

export const fetchUserListSuccess = (userList: IUserInfo[]): IFetchUserListSuccess => ({
  type: FETCH_USER_LIST_SUCCESS,
  payload: userList,
});

export const fetchUserListFailure = (error: string): IFetchUserListFailure => ({
  type: FETCH_USER_LIST_FAILURE,
  payload: error,
});

export default (filters:{}) => async (dispatch: Dispatch) => {
  dispatch(fetchUserListBegin());
  try {
    const userList = await getUserList(filters);
    dispatch(fetchUserListSuccess(userList));
  } catch (e) {
    dispatch(fetchUserListFailure(e.message));
  }
};
