import { connect } from "react-redux";
import { getUser, clearUser } from "../../store/user/actions";
import LoginComponent from "./LoginComponent";

const mapDispatchToProps = {
  getUser,
  clearUser,
};

export default connect(null, mapDispatchToProps)(LoginComponent);
