import { saveAs } from "file-saver";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { SmallButton } from "..";
import { fetchUserItemsCSV } from "../../services/api";
import { FiUpload } from "react-icons/fi";
import { IconContext } from "react-icons";
import { IProps } from "./interfaces";
import styles from "./styles";

const CsvDownloadButton = ({ query, type, message }: IProps) => {
  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();

  const getCSVContent = useCallback(async (): Promise<string> => {
    /*
      TODO: optimize this process by:
      1. send the translated column names to the backend to be used directly in the query
      2. use backend download with a download token instead of fetch
    */
    const rawCSVData = await fetchUserItemsCSV(query);
    const columnsEndIndex = rawCSVData.indexOf("\n");
    const contentStartIndex = columnsEndIndex + 1;
    const csvRecordsContent = rawCSVData.substring(contentStartIndex);

    const columnsString = rawCSVData.substring(0, columnsEndIndex);
    const columns = columnsString.split(",");

    const csvContent: string = `${columns.join(",")}\n${csvRecordsContent}`;
    return csvContent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const downloadResults = useCallback(async () => {
    setLoading(false);

    const csvContent = await getCSVContent();

    const blob = new Blob([csvContent], { type: "text/plain;charset=utf-8" });
    // @ts-ignore
    saveAs(blob, "user.csv");
  }, [setLoading, getCSVContent]);

  const handleClick = useCallback(() => {
    setLoading(true);
    downloadResults();
  }, [downloadResults]);

  return (
    <>
      {type === "button" && (
        <SmallButton type="deep-green" onClick={!loading ? handleClick : () => undefined}>
          <span className="icon-text">{message ? message : formatMessage({ id: "csvDownload.title" })}</span>
          <IconContext.Provider value={{ color: "white" }}>
            <FiUpload />
          </IconContext.Provider>
        </SmallButton>
      )}
      {type === "link" && (
        <span className="link-button" onClick={!loading ? handleClick : () => undefined}>
          {message ? message : formatMessage({ id: "csvDownload.title" })}
        </span>
      )}
      {/*// @ts-ignore */}
      <style jsx={true}>{styles}</style>
    </>
  );
};
export default CsvDownloadButton;
