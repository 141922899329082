import request from "../request";
import { LoginFactor1Response, LoginFactor2Response } from "@tagworx/core";

export const loginFactor1 = async (tenant: string, email: string, password: string): Promise<LoginFactor1Response> => {
  return request<LoginFactor1Response>({
    endpoint: `auth/login-factor1`,
    config: {
      method: "POST",
      body: JSON.stringify({ tenant, email, password }),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const loginFactor2 = async (tenant: string, email: string, password: string, code: string): Promise<LoginFactor2Response> => {
  return request<LoginFactor2Response>({
    endpoint: `auth/login-factor2`,
    config: {
      method: "POST",
      body: JSON.stringify({ tenant, email, password, code }),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const logout = async (): Promise<void> => {
  return request({
    endpoint: `auth/logout`,
    config: {
      method: "POST",
    },
  });
};

export const accountCreate2 = async (tenant: string, email: string, password: string, code: string): Promise<boolean> => {
  return request<boolean>({
    endpoint: `auth/account-create2`,
    config: {
      method: "POST",
      body: JSON.stringify({ tenant, email, password, code }),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};

export const validCode = async (tenant: string, email: string, code: string, loginMethod: string): Promise<boolean> => {
  return request<boolean>({
    endpoint: `auth/is-code-valid`,
    config: {
      method: "POST",
      body: JSON.stringify({ tenant, email, code, loginMethod }),
      headers: {
        "Content-type": "application/json",
      },
    },
  });
};
