import css from "styled-jsx/css";

const styles = css`
  .user-admin-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }
`;

export default styles;
