import React from "react";
import { IProps } from "./interfaces";
import styles, { body } from "./styles";
import { NavBar } from "..";

export default ({ children }: IProps) => {
  return (
    <>
      <div className="page">
        <div className="page__main">
          <NavBar />
          <div className="page__content">{children}</div>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
      <style jsx={true} global={true}>
        {body}
      </style>
    </>
  );
};
