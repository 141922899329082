import React from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { routes } from "../../config";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./styles";
import messages from "./messages";
import { IProps } from "./interfaces";
import { useCheckPermission } from "../../hooks";

const SubNavigator = ({ page }: IProps) => {
  const user = useSelector((state: any) => state.user);
  const location = useLocation();
  const inventorySettingsReadPermission = useCheckPermission("inventory-settings", "read");
  const options = {
    settings: {
      title: messages.settings,
      items: [
        {
          to: `${routes.settings}${routes.accountSettings}`,
          label: messages.accountSettings,
        },

        ...(useCheckPermission("user", "read")
          ? [
              {
                to: `${routes.settings}${routes.userAdmin}`,
                label: messages.users,
                exact: false,
              },
            ]
          : []),
        ...(useCheckPermission("role", "read")
          ? [
              {
                to: `${routes.settings}${routes.roles}`,
                label: messages.roles,
              },
            ]
          : []),

          ...(useCheckPermission("user", "read")
          ? [
              {
                to: `${routes.settings}${routes.userActivity}`,
                label: messages.userActivity,
                exact: false,
              },
            ]
          : []),
      ],
    },
  };

  const { title, items } = options[page];
  return (
    <>
      <header className="subnavigator__container">
        <h1>
          <FormattedMessage {...title} />
        </h1>
        <ul>
          {inventorySettingsReadPermission && (
            <li>
              <a href={`${process.env.REACT_APP_INVENTORY_ROUTING_URL}${routes.generalSettings}`}>{<FormattedMessage {...messages.generalSettings} />}</a>
            </li>
          )}
          {inventorySettingsReadPermission && (
            <li>
              <a href={`${process.env.REACT_APP_INVENTORY_ROUTING_URL}${routes.appSettings}`}>{<FormattedMessage {...messages.appSettings} />}</a>
            </li>
          )}
          {inventorySettingsReadPermission && (
            <li>
              <a href={`${process.env.REACT_APP_INVENTORY_ROUTING_URL}${routes.editTable}`}>{<FormattedMessage {...messages.fieldSettings} />}</a>
            </li>
          )}
          {items.map(({ to, label, exact = false }) => (
            <li key={to}>
              <a className={location.pathname === to ? "active-route" : ""} href={to}>
                <FormattedMessage {...label} />
              </a>
            </li>
          ))}
        </ul>
      </header>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default SubNavigator;
