import { UserCSVFieldMap } from "@tagworx/core";

const csvFieldMapKey = "csvFieldMapKey";
const kitsCsvFieldMapKey = "kitsCsvFieldMapKey";

const getFieldMapKey = (forItems: boolean) => (forItems ? csvFieldMapKey : kitsCsvFieldMapKey);
export const saveCsvFieldMap = (forItems: boolean, fieldMap: UserCSVFieldMap) => {
  localStorage.setItem(getFieldMapKey(forItems), JSON.stringify(fieldMap));
};

export const getCsvFieldMap = (forItems: boolean) => {
  const fieldMap = localStorage.getItem(getFieldMapKey(forItems));
  if (!fieldMap) return undefined;
  try {
    const parsedFieldMap: UserCSVFieldMap = JSON.parse(fieldMap);
    return parsedFieldMap;
  } catch {
    return undefined;
  }
};
