import css from "styled-jsx/css";
import { theme } from "../../config";

const {
  colors: { Blue_1 },
} = theme;

export default css`
  .link-button {
    text-decoration: underline;
    cursor: pointer;
    color: ${Blue_1};
  }
`;
