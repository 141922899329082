import css from "styled-jsx/css";

export default css`
  .hide {
    display: none;
  }

  .select-csv-text {
    max-width: 600px;
    margin-bottom: 30px;
  }

  .buttons {
    display: flex;
    margin-top: 28px;
    margin-bottom: 24px;
  }

  .buttons :global(button) {
    margin-left: 8px;
    width: 69px;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
`;

export const loaderColor = "#041e4f";
