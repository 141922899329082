export default {
  "formPlaceholders.barcode": "Barcode",
  "formPlaceholders.date": "Date & Time",
  "formPlaceholders.mobileNumber": "Mobile number",
  "formPlaceholders.peoplesoftId": "Subject ID",
  "formPlaceholders.firstName": "First name",
  "formPlaceholders.lastName": "Last name",
  "formPlaceholders.email": "Email address",
  "formPlaceholders.role": "Role",
  "formPlaceholders.expirationAt": "Expiration Date",
  "formPlaceholders.activationAt": "Activation Date",
};
