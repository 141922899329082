import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { IStateTree } from "../../../store/interfaces";
import { SmallButton, Switcher, TextInput } from "../../../components";
import RoleCard from "../RoleCard";
import { ArrowBack } from "../icons";

import { IProps } from "./interfaces";
import styles from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";

const RoleEditor = ({ editedRole, cancel, saveRole, deleteRole }: IProps) => {
  const intl = useIntl();
  const [role, setRole] = useState(editedRole);
  const saving = useSelector(({ roles }: IStateTree) => roles.saving);
  const handleRoleNameChange = useCallback(
    (name) => {
      setRole((oldRole) => ({
        ...oldRole,
        name,
      }));
    },
    [setRole],
  );

  const handleTogglePermission = useCallback(
    (permission: string, command: "write" | "read") => {
      if (saving) return;
      setRole((oldRole) => ({
        ...oldRole,
        permissions: {
          ...oldRole.permissions,
          [permission]: {
            ...oldRole.permissions[permission],
            [command]: !oldRole.permissions[permission]![command],
          },
        },
      }));
    },
    [setRole, saving],
  );

  const handleSaveRole = useCallback(() => {
    saveRole(role);
  }, [role, saveRole]);

  const handleDeleteRole = useCallback(() => {
    if (window.confirm(intl.formatMessage({ id: "roles.confirmDelete" }))) {
      deleteRole(role);
    }
  }, [role, deleteRole, intl]);

  return (
    <>
      <div className="container">
        <div className="editor">
          <form>
            <fieldset disabled={saving}>
              <div className="top-row">
                <div className="top-row__left">
                  <button className="back" onClick={cancel} type="button" title="back">
                    <ArrowBack />
                  </button>
                  <legend>
                    <FormattedMessage {...(role._id ? messages.editRole : messages.newRole)} />
                  </legend>
                </div>
                <SmallButton type="deep-blue" onClick={handleSaveRole} disabled={role.name.length === 0}>
                  <FormattedMessage {...messages.saveRole} />
                </SmallButton>
              </div>
              <TextInput idFor="name" value={role.name} onChange={handleRoleNameChange} placeholder="Name" description="e.g.: John Doe" />
              <h1>
                <FormattedMessage {...messages.permissions} />
              </h1>
              {Object.keys(editedRole.permissions).map((permission) => {
                const name = permission.slice(0, 1).toUpperCase() + permission.slice(1);
                return (
                  <div className="permission" key={permission}>
                    <div className="permission-name">{name}</div>
                    <div className="toggle">
                      <div className="toggle-name">
                        <FormattedMessage {...messages.view} />
                      </div>
                      <Switcher active={(role.permissions[permission] || { read: false }).read} onToggle={() => handleTogglePermission(permission, "read")} />
                    </div>
                    <div className="toggle">
                      <div className="toggle-name">
                        <FormattedMessage {...messages.edit} />
                      </div>
                      <Switcher active={(role.permissions[permission] || { write: true }).write} onToggle={() => handleTogglePermission(permission, "write")} />
                    </div>
                  </div>
                );
              })}
            </fieldset>
          </form>
          {role._id && (
            <button className="delete-button" onClick={handleDeleteRole} disabled={saving}>
              <FormattedMessage {...messages.deleteRole} />
            </button>
          )}
        </div>
        <div className="preview">
          <RoleCard role={{ ...role, name: "Role Summary" }} preview={true} />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default RoleEditor;
