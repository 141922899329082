export default {
  "login.header1": "Login to your account",
  "login.header2": "Multi-factor authentication",
  "login.description1": "Enter your email and password",
  "login.description2": "Enter the code received in SMS",
  "login.email": "Email address",
  "login.password": "Password",
  "login.code": "SMS code",
  "login.signin": "Sign In",
  "login.back": "Back",
  "login.error": "Error",
  "login.factor1failure": "Email and password do not match, or incorrect tenant!",
  "login.factor2failure": "Invalid SMS code!",
  "login.emaildescription": "e.g. johndoe@labworx.com",
  "login.passworddescription": "Minimum 6 characters including at least one number",
  "login.codedescription": "Please enter your one-time password sent by text message",
  "login.forgotpass": "Forgot your password?",
  "login.smsnotreceived": "Didn’t receive the SMS? Send again!",
  "login.tenant": "Company Name",
  "login.tenantdescription": "e.g. Worx Software",
  "login.rateLimiterErrorPart0": "You have exceeded the maximum of",
  "login.rateLimiterErrorPart1": "requests in",
  "login.rateLimiterErrorPart2": "minute. You will now have to wait",
  "login.rateLimiterErrorPart3": "seconds before making a new attempt.",
  "login.userBlocked": "Your account has been locked, please contact your system administrator.",
};
