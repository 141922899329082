import React from "react";

export default () => (
  <svg width="181" height="34" viewBox="0 0 181 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M86.0068 8.51339L81.6722 13.8142L70.624 0H65.4963L79.1348 16.8661L72.0512 25.5402H77.1789L81.6722 20.0252L93.249 34H98.3766L84.2624 16.8661L91.0816 8.51339H86.0068Z"
      fill="#2A4B9A"
    />
    <path
      d="M26.6954 8.51338L20.6691 25.4866H17.2859L13.3741 14.5102L9.46232 25.4866H6.02628L0 8.51338H4.17611L7.71787 18.6866L11.2068 8.51338H15.4357L15.3829 8.62047L18.8718 18.6331L22.5193 8.45984H26.6954V8.51338ZM48.2631 20.1323C48.2631 21.685 47.7345 22.9701 46.6773 23.9874C45.62 25.0047 44.2985 25.5402 42.7655 25.5402H32.9331C31.4001 25.5402 30.1314 25.0047 29.0213 23.9874C27.9641 22.9701 27.4355 21.685 27.4355 20.1323V13.8677C27.4355 12.315 27.9641 11.0299 29.0213 10.0126C30.0786 8.99527 31.4001 8.45984 32.9331 8.45984H42.8183C44.3513 8.45984 45.62 8.99527 46.7301 10.0126C47.7874 11.0299 48.316 12.315 48.316 13.8677V20.1323H48.2631ZM44.3513 20.1323V13.8677C44.3513 12.9039 43.8227 12.4756 42.7655 12.4756H32.9331C31.8759 12.4756 31.3473 12.9575 31.3473 13.8677V20.0787C31.3473 21.0425 31.8759 21.4709 32.9331 21.4709H42.8183C43.8227 21.5244 44.3513 21.0425 44.3513 20.1323ZM69.6194 25.4866H64.2804L55.8753 15.9559H65.972C66.712 15.9559 67.0292 15.3669 67.0292 14.189C67.0292 13.011 66.6592 12.422 65.972 12.422H53.8137V25.4331H49.9019V8.51338H66.1834C67.6636 8.51338 68.8794 9.10236 69.7252 10.2803C70.5181 11.3512 70.941 12.6898 70.941 14.2425C70.941 15.7953 70.5181 17.0803 69.7252 18.2047C68.8794 19.3827 67.6636 19.9717 66.1834 19.9717H64.7033L69.6194 25.4866Z"
      fill="white"
    />
    <path
      d="M98.3766 34H93.3018L81.725 20.0252L77.1789 25.4866H72.1041L79.1348 16.8661L65.4963 0H70.5711L81.6722 13.8142L86.0068 8.51339H91.0816L84.2095 16.9197L98.3766 34Z"
      fill="#0ECAFF"
    />
    <path d="M68.6151 29.7701L65.1262 34H70.201L73.6899 29.7701H68.6151Z" fill="#04C5B5" />
    <path d="M94.5704 4.22992L98.0593 0H92.9317L89.4956 4.22992H94.5704Z" fill="#04C5B5" />
    <path d="M96.0504 21.5244H93.7773V19.5969H96.0504V21.5244Z" fill="white" />
    <path
      d="M106.623 18.9543C106.623 19.6504 106.411 20.2394 105.988 20.7213C105.565 21.2567 104.984 21.4709 104.297 21.4709H97.2662V19.5433H104.297C104.561 19.5433 104.72 19.3291 104.72 18.9008C104.72 18.4724 104.614 18.2583 104.297 18.2583H99.1692C98.482 18.2583 97.9534 17.9906 97.4776 17.5087C97.0547 17.0268 96.8433 16.4378 96.8433 15.7417C96.8433 15.0457 97.0547 14.4567 97.4776 13.9213C97.9005 13.4394 98.482 13.1717 99.1692 13.1717H106.2V15.0992H99.1692C98.9049 15.0992 98.7463 15.3134 98.7463 15.7417C98.7463 16.1701 98.852 16.3307 99.1692 16.3307H104.297C104.984 16.3307 105.513 16.5984 105.988 17.0803C106.411 17.6693 106.623 18.2583 106.623 18.9543Z"
      fill="white"
    />
    <path
      d="M117.512 18.9008C117.512 19.6504 117.248 20.2929 116.72 20.7748C116.191 21.2567 115.557 21.5244 114.816 21.5244H110.006C109.266 21.5244 108.632 21.2567 108.103 20.7748C107.574 20.2929 107.31 19.6504 107.31 18.9008V15.8488C107.31 15.0992 107.574 14.4567 108.103 13.9748C108.632 13.4929 109.266 13.2252 110.006 13.2252H114.816C115.557 13.2252 116.191 13.4929 116.72 13.9748C117.248 14.4567 117.512 15.0992 117.512 15.8488V18.9008ZM115.609 18.9008V15.9024C115.609 15.4205 115.345 15.2063 114.869 15.2063H110.059C109.53 15.2063 109.319 15.4205 109.319 15.9024V18.9008C109.319 19.3827 109.583 19.5969 110.059 19.5969H114.869C115.345 19.5969 115.609 19.3827 115.609 18.9008Z"
      fill="white"
    />
    <path d="M127.028 15.2063H120.208V16.4378H126.605V18.3654H120.208V21.5244H118.305V13.2787H127.028V15.2063Z" fill="white" />
    <path d="M136.596 15.2063H132.895V21.5244H130.992V15.2063H127.292V13.2787H136.543V15.2063H136.596Z" fill="white" />
    <path
      d="M150.128 13.2787L147.221 21.5244H145.582L143.679 16.1701L141.776 21.5244H140.085L137.177 13.2787H139.186L140.93 18.2047L142.622 13.2787H144.684L144.631 13.3323L146.322 18.2047L148.067 13.2787H150.128Z"
      fill="white"
    />
    <path
      d="M160.489 21.5244H158.586V18.9008H153.564V16.9732H158.586V15.7953C158.586 15.3669 158.428 15.1528 158.163 15.1528H152.771C152.507 15.1528 152.349 15.3669 152.349 15.7953V21.5244H150.446V15.7953C150.446 15.0992 150.657 14.5102 151.08 13.9748C151.503 13.4929 152.084 13.2252 152.719 13.2252H158.111C158.798 13.2252 159.379 13.4929 159.802 13.9748C160.225 14.4567 160.437 15.0992 160.437 15.7953V21.5244H160.489Z"
      fill="white"
    />
    <path
      d="M170.85 21.5244H168.26L164.19 16.9197H169.106C169.476 16.9197 169.634 16.652 169.634 16.063C169.634 15.474 169.476 15.2063 169.106 15.2063H163.185V21.5244H161.282V13.2787H169.212C169.952 13.2787 170.533 13.5465 170.956 14.1354C171.326 14.6709 171.537 15.3134 171.537 16.063C171.537 16.8126 171.326 17.4551 170.956 17.9906C170.533 18.5795 169.952 18.8473 169.212 18.8473H168.471L170.85 21.5244Z"
      fill="white"
    />
    <path d="M181 15.2063H174.181V16.4378H180.524V18.3654H174.181V19.5969H180.947V21.5244H172.278V13.2787H181V15.2063Z" fill="white" />
  </svg>
);
