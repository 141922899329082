import css from "styled-jsx/css";
import { theme } from "../../../config";

const {
  colors: { white, Grey_1 },
} = theme;

export default css`
  .role-card {
    background-color: ${white};
    filter: drop-shadow(0px 0px 15px rgba(129, 129, 129, 0.25));
    border-radius: 4px;
    padding-bottom: 14px;
  }

  .role-card__top {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px ${Grey_1};
    height: 49px;
  }

  .role-card__name {
    padding: 12px 0 0 13px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
  }

  .role-card__edit {
    padding: 16px 16px 15px;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  .permission {
    padding-left: 13px;
    padding-top: 8px;
    padding-bottom: 9px;
  }
  .permission__name {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    margin-bottom: 7px;
  }
  .permission__commands {
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 12px;
    font-weight: 700;
  }

  .permission__commands :global(svg) {
    margin-right: 2px;
  }
  .permission__commands :global(svg):nth-of-type(2) {
    margin-left: 13px;
  }
`;
