import React from "react";
import { useIntl } from "react-intl";
import { SmallButton } from "../../../../../../components";
import { IProps } from "./interfaces";

import messages from "./messages";
import styles from "./styles";

export default ({ onDone }: IProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <h1> {formatMessage(messages.ready)}</h1>
      <p> {formatMessage(messages.readyDescription)}</p>
      <div className="buttons">
        <SmallButton type="blue" onClick={onDone}>
          {formatMessage(messages.done)}
        </SmallButton>
      </div>
      {/*// @ts-ignore */}
      <style jsx={true}>{styles}</style>
    </>
  );
};
