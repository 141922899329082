import { Dispatch } from "redux";
import { IGetSettingsBegin, IGetSettingsFailure, IGetSettingsSuccess, ISettings } from "../models";
import { GET_SETTINGS_BEGIN, GET_SETTINGS_FAILURE, GET_SETTINGS_SUCCESS } from "../types";
import { getSettings } from "../../../services/api";

const getSettingsBegin = (): IGetSettingsBegin => ({
  type: GET_SETTINGS_BEGIN,
});

const getSettingsFailure = (error: string): IGetSettingsFailure => ({
  type: GET_SETTINGS_FAILURE,
  payload: error,
});

const getSettingsSuccess = (settings: ISettings): IGetSettingsSuccess => ({
  type: GET_SETTINGS_SUCCESS,
  payload: settings,
});

export default () => async (dispatch: Dispatch) => {
  dispatch(getSettingsBegin());
  try {
    const settings = await getSettings();
    dispatch(getSettingsSuccess(settings));
  } catch (e) {
    const { message } = e as Error;
    dispatch(getSettingsFailure(message));
  }
};
